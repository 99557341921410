import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Toolbar from '@/components/base/Toolbar'
import ToolbarTemplate1 from '@/components/base/template/ToolbarTemplate1'
import ToolbarTemplate3 from '@/components/base/template/ToolbarTemplate3'
import Wallet from '@/components/base/Wallet'
import WalletTemplate3 from '@/components/base/template/WalletTempate3'
import Dialog from '@/components/dialog'
import SelectList from '@/components/dialog/Select'
import { resStatus } from '@/utils/resUtils'
import { spec } from '@/utils/money'
import { clickEventInterval } from '@/utils/event'
import { timezone } from '@/utils/day'
import countdown from '@/components/countdown1'
import maintenance from '@/components/maintenance'
import { Decimal } from 'decimal.js'
export default {
  components: {
    Toolbar,
    Wallet,
    SelectList,
    Dialog,
    WalletTemplate3,
    ToolbarTemplate3,
    ToolbarTemplate1,
    countdown,
    maintenance,
  },

  data: () => ({
    valid: false,
    tab: 0,
    maintenance: false, // 維修中
    mode: '',

    form: {
      from_walletcode: '', // 轉出錢包
      to_walletcode: '', // 輸入錢包
      amount: '', // 轉換金額
    },

    inputDisplay: {
      from: '',
      to: '',
    },
    walletList: [],
    walletListAll: [],
    gameList: [],
    transferWalletList: [],
    transferTargetWallets: [],
    transferTargetToWallets: [],
    transferTargetFromWallets: [],
    // 全包回收(錢包) 狀態
    transferWallertAll: null,

    selectDialog: {
      type: '',
      status: false,
      list: [],
    },

    walletBalance: {
      from: '',
      to: '',
    },

    loading: {
      transferBtn: false,
      allRetrieve: false,
    },

    dialog: {
      status: false,
      type: '',
    },

    // 目前 active 的錢包 (style)
    activeWallet: {},

    // wallet loading
    walletLoading: false,

    // 執行過後，是否可以再打 api
    eventLaunch: {
      walletFetch: null,
      retrieveAllWalletBalance: null,
      mutiRetrieve: null,
    },

    eventWalletDetails: [], // 活動錢包
    eventWalleRulesContent: [], // 活動錢包 規則內容
    eventWalleRulesDialog: {
      status: false,
      type: '',
    },
    eventWalletForm: {
      timezone: '',
      page: 1,
      bonus_type: 'all',
      promo_id: null,
    },
    // 活動錢包 規則 彈窗
    isCountdownFinished: [], // 追踪每個 game 的倒數計時狀態
    unlockEventAwards: false, // 活動錢包 解鎖按鈕
  }),

  computed: {

    ...mapState({
      // 中心錢包
      userMainBalance: state => state.wallet.main,
      // 是否開啟免轉模式
      walletMode: state => state.user.userData.member?.wallet_type,
      settings: state => state.websiteData.settings,
      player: state => state.wallet.player,
      // 是否使用活動錢包模式
      promotionEventWallet: state => state.websiteData.func_switch
        ?.promotion_event_wallet,
    }),

    ...mapGetters(['baseImgUrl', 'currencySymbol', 'currencySymbol_', 'theme']),

    tabText() {
      const result = [
        this.$t('global.vendor') + this.$t('global.wallet'),
      ]

      // 關閉免轉模式＋開啟活動錢包
      if (!this.walletModeTrueFalse && this.promotionEventWallet) {
        result.push(this.$t('global.eventWalle') + this.$t('global.wallet'))
      }

      return result
    },
    primaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.primary
    },
    walletModeTrueFalse: {
      // 免轉模式
      get() {
        return this.walletMode === 'single'
      },
      set(newStatue) {
        const walletType = newStatue ? 'single' : 'transfer'
        this.setUserMode({ wallet_type: walletType })

        if (walletType === 'transfer' && this.hasBalanceCanRetrieve) { return this.openSetUserModeDialog(walletType) }
      },

    },

    /**
   * 輸入轉換金額 驗證
   * - 必填
   * - 不得高於目前轉出的錢包餘額
   * @date 2021-04-14
   * @returns {string} 警示文字
   */
    rules() {
      return {
        required: value => !!value || this.$t('form.missAmount'),
        decimal: value => /^\d+(\.\d{1,2})?$/.test(value) || this.$t('global.amount') + this.$t('global.fail'),
        overZero: value => value > 0 || this.$t('global.amount') + this.$t('global.fail'),
        overMaxBalance: (value) => {
          if (value && /^\d+(\.\d{1,2})?$/.test(value)) {
            return new Decimal(value).lessThanOrEqualTo(this.walletBalance.from) || this.$t('form.errorExcessAmount', { limit: this.walletBalance.from })
          }
          return true
        },
      }
    },
    /**
   * 場館錢包是否有可以回收的項目 全部回收btn
   * @date 2021-04-14
   * @returns {object} 可以回收的場館錢包
   */
    hasBalanceCanRetrieve() {
      const zero = ['0.00', 0, '0']
      return this.walletList.find(item => !zero.includes(item.balance))
    },

    /**
   * 大於 0 的錢包加總
   * @date 2021-09-07
   * @returns {number} 加總金額
   */
    walletBalanceTotal() {
      return this.gameList.reduce((acc, cur) => acc + Number(cur.balance), 0)
    },
  },

  watch: {
    'form.from_walletcode'(newWalletCode) {
      if (this.$refs.form) this.$refs.form.reset()
    },
  },

  async mounted() {
    this.set_toolbar_title(this.$t('global.transfer'))
    await this.userDataRefresh()
    this.getEventWalletDetails()
    this.eventWalletForm.timezone = timezone()
    if (this.walletMode === 'single' && this.promotionEventWallet) this.setUserMode({ wallet_type: 'transfer' })
    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
    // 在組件掛載後檢查 URL 中的查詢參數
    if (this.$route.query.tab === 'eventWallet') {
      this.tab = 1
    }
  },

  methods: {
    ...mapActions([
      'get_wallet_list_new',
      'set_toolbar_title',
      'get_user_balance',
      'get_user_wallet_list',
      'set_user_wallet_mode',
      'set_user_data',
      'user_transfer_wallet',
      'show_alert',
      'set_progress_status',
      'user_retrieve_all_wallet_balance',
      'multiple_wallet_transfer_to_main_wallet',
      'set_main_balance',
      'set_locked_balance',
      'user_data_refresh',
      'event_wallet_details',
      'unlock_event_awards',
      'get_bonus_news',
    ]),

    ...mapMutations([
      'SET_USER_WALLET_TYPE',
      'SET_FREE_BALANCE',
      'SET_PLAYER_BALANCE',
      'SET_LOCKED_WALLET_BALANCE',
    ]),
    /**
     * 活動錢包 解鎖按鈕 (成功)
     * @date 2024-10-15
     * @param {objecg} data
     */
    unlockEventAwardsSuccess() {
      this.getEventWalletDetails()
      this.show_alert({
        icon: 'done',
        text: this.$t('global.receive') + this.$t('global.eventWalle') + this.$t('global.wallet'),
      })
    },

    /**
     * 活動錢包 解鎖按鈕
     * @date 2024-10-15
     * @param {string} wallet_code
     */
    async aipUnlockEventAwards(wallet_code) {
      this.loading.transferBtn = true
      try {
        const res = await this.unlock_event_awards({ wallet_code: wallet_code })
        this.loading.transferBtn = false
        resStatus(res, () => this.unlockEventAwardsSuccess(), this.eventWalletFail())
      } catch (error) {
        this.loading.transferBtn = false
        this.eventWalletFail() // 處理錯誤情況
      }
    },
    /**
    * 卡片未解鎖且倒數時間已到期
    * @date 2024-10-15
    * @param {boolean} 倒數時間是否到期
    */
    handleTimeUp(isTimeUp, index) {
      // 如果接收到的參數是 false，表示倒數結束，將對應 index 的布林值設置為 false
      if (!isTimeUp) {
        this.$set(this.isCountdownFinished, index, false)
      }
    },
    isPromotionExpired(endTime) {
      // 檢查時間是否到期
      const currentTime = Math.floor(Date.now() / 1000)
      //  endTime 不等於 - 1（不是常駐活動） 或 已過期
      return endTime !== -1 && currentTime > endTime
    },
    /**
     * 活動錢包 解鎖按鈕
     * @date 2024-10-15
     * @param {string} end_time_sec 到期時間、wallet_code
     */
    handlerUnlockEventAwards(end_time_sec, wallet_code) {
      const endTime = end_time_sec
      if (this.isPromotionExpired(endTime)) {
        // 卡片已到期 顯示提示信息，如彈出對話框或通知用戶
        this.show_alert({
          icon: 'fail',
          text: this.$t('global.eventWalle') + this.$t('global.Expired'),
        })
        this.eventWalletDetails = []
        this.getEventWalletDetails()
      } else {
        // 是常駐活動 && 沒到期
        // 執行解鎖獎勵的邏輯
        this.aipUnlockEventAwards(wallet_code)
      }
    },
    /**
       * 活動錢包 計算進度值，保證最大值為 100，並無條件捨去小數點
       * @date 2024-10-015
       * @param  {string} game.valid_beta 目前流水、game.promotion.threshold 流水門檻
       */
    getProgressValue(game) {
      const progressValue = Math.floor(Math.min((game.valid_bet / game.promotion.threshold) * 100, 100))
      // 進度條>=100解鎖
      this.unlockEventAwards = progressValue >= 100

      return progressValue
    },

    activeWalletBtnStyle(active) {
      if (active) {
        return {
          border: `1px solid ${this.primaryClr}`,
        }
      }
      return ''
    },
    eventWalletFail() {
      this.show_alert({
        icon: 'fail',
      })
    },
    /**
     * 取得活動錢包 說明內容 (成功)
     * @date 2021-08-02
     * @param {objecg} data
     */
    async eventWalletRulessSuccess({ bonus_news: data }) {
      this.eventWalleRulesContent = data.data[0]
    },
    /**
     * 取得活動錢包 說明內容
     * @date 2021-03-16
     * @returns {any}
     */
    async eventWalletRules(promotionId) {
      console.log('promotionId', promotionId)
      this.eventWalleRulesDialog.status = true
      this.eventWalletForm.promo_id = promotionId
      const res = await this.get_bonus_news(this.eventWalletForm)
      resStatus(res, this.eventWalletRulessSuccess, this.eventWalletFail)
    },
    /**
     * 取得使用者活動錢包 (成功)
     * @date 2021-09-24
     * @param {any} data
     * @returns {any}
     */
    getEventWalletDetailsSuccess(data) {
      this.eventWalletDetails = data.details
      // 初始化 isCountdownFinished 陣列，為每個 game 設置初始值為 true
      this.isCountdownFinished = Array(this.eventWalletDetails.length).fill(true)
    },
    /**
     * 取得使用者活動錢包
     * @date 2021-09-24
     */
    async getEventWalletDetails() {
      this.walletLoading = true
      const res = await this.event_wallet_details()
      resStatus(res, this.getEventWalletDetailsSuccess, this.walletTransferFail)
    },
    /**
     * 使用者資料更新 (成功)
     * - 取得場館錢包
     * - 取得中心/鎖定 錢包
     * @date 2021-10-27
     */
    userDataRefreshSuccess() {
      this.get_user_balance({ walletcode: 'player_balance' })
      this.get_user_balance({ walletcode: 'locked_balance' })
      this.getUserWalletList()
    },

    /**
     * 使用者資料更新
     * @date 2021-10-27
     */
    async userDataRefresh() {
      const res = await this.user_data_refresh()
      resStatus(res, this.userDataRefreshSuccess)
    },

    /**
     * 更新所有錢包狀態 for toolbar
     * @date 2021-04-14
     */
    fetchWallet() {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.walletFetch.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.walletFetch.setStatus(false)

      this.get_user_balance({ walletcode: 'player_balance' })
      this.get_user_balance({ walletcode: 'locked_balance' })
      this.getUserWalletList()
    },

    /**
     * 設置默認的 轉入 錢包
     */
    setDefaultTo() {
      const ifFirstWallet = this.getFirstWallet(this.transferTargetFromWallets)
      // console.log(ifFirstWallet)
      // 若有從from的錢包列表中(transferTargetFromWallets)成功取得第一個非維護的錢包資訊,則用該錢包資訊接著請求to的錢包列表
      if (ifFirstWallet) {
        this.setWalletCode({
          walletCode: ifFirstWallet.code,
          name: ifFirstWallet.name,
          type: 'from',
        })
        return
      }
      this.show_alert({ icon: 'fail' })
      console.error('from錢包列表未找到非維護中的錢包!')
    },
    /**
     * 取得錢包列表中第一個非維護錢包資訊
     * @param {any} list
     * @returns {any}
     */
    getFirstWallet(list) {
      // console.log(list.find(l => l.status !== '0'))
      return list.find(l => l.status !== '0') || false
    },
    /**
     * 取得使用者所有遊戲錢包 (成功)
     * @date 2021-09-24
     * @param {any} data
     * @returns {any}
     */
    async getUserWalletListSuccess(data) {
      const { game, player, free, event } = data

      // 更新中心錢包金額 @player
      const mainBalance = player.find(item => item.wallet_code === 'player_balance')
      const lockedBalance = player.find(item => item.wallet_code === 'locked_balance')
      this.set_main_balance(Number(mainBalance.balance))
      this.set_locked_balance(Number(lockedBalance.balance))

      this.SET_FREE_BALANCE(free.find(wallet => wallet.wallet_code === 'free_wallet'))
      this.SET_PLAYER_BALANCE(player.find(wallet => wallet.wallet_code === 'player_balance'))
      this.SET_LOCKED_WALLET_BALANCE(player.find(wallet => wallet.wallet_code === 'locked_balance'))

      this.walletList = [...event, ...game]
      this.walletListAll = [...game, ...player, ...free, ...event] // mapping錢包資料用的錢包列表
      await this.getSelectOrAllTransferWalletList() // 取得全部轉換錢包list或單一錢包對應的list
      this.gameList = game
      this.selectDialog.list = [...event, ...game]
      this.setDefaultTo()

      this.walletLoading = true
    },

    /**
     * 取得使用者所有遊戲錢包
     * @date 2021-09-24
     */
    async getUserWalletList() {
      this.walletLoading = false
      const res = await this.get_user_wallet_list()
      resStatus(res, this.getUserWalletListSuccess, this.walletTransferFail)
    },

    /**
     * 取得使用者可轉換錢包列表(維護中的錢包也會顯示但不可進行轉換)
     * @param {any} walletCode 未提供預設給全部,若有提供則指定取得該錢包對應的錢包列表
     * @param {any} type from或to
     */
    async getSelectOrAllTransferWalletList(walletCode, type) {
      const oppositeType = type === 'from' ? 'to' : 'from'
      const param = walletCode && type ? { [`${type}_walletcode`]: walletCode } : {}
      const walletKey = type === 'from' ? 'transferTargetToWallets' : 'transferTargetFromWallets'

      // 获取钱包列表
      const { data, errors } = await this.get_wallet_list_new(param)

      if (errors) {
        this.clearWalletData(oppositeType)
        this[walletKey] = []
        this.show_alert({ icon: 'fail', text: errors[0] })
        return
      }
      this[walletKey] = this.transferTargetWalletsMapping(data?.target_wallets || [])

      const ifFirstWallet = this.getFirstWallet(this[walletKey])

      if (ifFirstWallet) {
        this.updateWalletData(ifFirstWallet, oppositeType)
        if (type === 'from') {
          // 處理新取得的to列表的餘額顯示
          this.dispalySelectWalletBalance(this.form.to_walletcode, 'to')
        }
      } else {
        this.clearWalletData(oppositeType)
      }
    },
    clearWalletData(oppositeType) {
      // 清除指定from或to的錢包名稱&餘額顯示&form資料
      this.inputDisplay[oppositeType] = ''
      this.form[`${oppositeType}_walletcode`] = ''
      this.walletBalance[oppositeType] = ''
    },
    updateWalletData(wallet, oppositeType) {
      this.inputDisplay[oppositeType] = wallet.name
      this.form[`${oppositeType}_walletcode`] = wallet.code
    },
    /**
     * mapping錢包餘額&遊戲錢包狀態
     * @param {any} transferTargetWallets
     */
    transferTargetWalletsMapping(transferTargetWallets) {
      const mappingRes = transferTargetWallets.map(target => {
        const matched = this.walletListAll.find(wallet => wallet.wallet_code === target.code)
        if (matched) {
          const res = { ...target, balance: matched.balance }
          if (matched?.status) {
            res.status = matched.status
          }
          if (matched?.wallet?.name) {
            res.name = matched.wallet.name
          }
          return res
        }
        return target
      })
      return mappingRes
    },
    openSetUserModeDialog(type) {
      this.dialog.type = type
      this.dialog.status = true
    },

    /**
     * 設置使用者使用模式 (成功)
     * - 重新取得使用者資料
     * @date 2021-10-27
     * @param {any} data
     * @returns {any}
     */
    setUserModeSuccess(data) {
      this.clearSeleted()

      this.cancelRecycleWalletBtn()

      this.userDataRefresh()
      if (data.wallet_type === 'single') this.resetDialog()
    },

    /**
     * 設置使用者使用模式 (一般/免轉)
     * @date 2021-04-13
     * @param {string} walletModel 錢包模式 'transfer' : 'single'
     */
    async setUserMode(walletModel) {
      const res = await this.set_user_wallet_mode(walletModel)
      resStatus(res, this.setUserModeSuccess)
    },

    openSelectListDialog(type) {
      this.selectDialog.type = type
      this.selectDialog.list = type === 'from' ? this.transferTargetFromWallets : this.transferTargetToWallets
      this.selectDialog.status = true
    },

    /**
     * 檢查場館錢包，是否符合原則
     * - 如果不是重覆項目
     *   - 必須其一為「中心錢包」 (不可錢包互轉)
     * - 轉出/轉入為同場館 (皆為中心錢包)
     * - 轉出/轉入為同場館 (不為中心錢包)
     * @date 2021-04-13
     * @param {string} nowSetwalletCode 目前設置錢包的 錢包代碼
     * @param {string} nowSettype 目前設置錢包的狀態 (from / to)
     * @returns {function} 執行另一個狀態的設置，若非重覆則跳出
     */
    checkSelectedItem(nowSetwalletCode, nowSettype) {
      const otherType = nowSettype === 'from' ? 'to' : 'from'
      const doubleSelect =
        this.form[`${otherType}_walletcode`] === nowSetwalletCode
      const selectWalletIsUserMainWallet = nowSetwalletCode === 'player_balance'

      if (!doubleSelect) {
        if (this.walletBalance.from && this.walletBalance.to) {
          const copyFromNtoAry = [
            this.form.from_walletcode,
            this.form.to_walletcode,
          ]

          if (!copyFromNtoAry.includes('player_balance')) {
            this.setWalletCode({
              walletCode: 'player_balance',
              name: this.player.name,
              type: otherType,
            })
          }
        } else return false
      } else {
        const firstWallet = this.walletList[0]

        selectWalletIsUserMainWallet
          ? this.setWalletCode({
            walletCode: firstWallet.wallet_code,
            name: firstWallet.wallet.name,
            type: otherType,
          })
          : this.setWalletCode({
            walletCode: 'player_balance',
            name: this.player.name,
            type: otherType,
          })
      }
    },

    /**
     * 設置目前選擇的場館錢包 (轉出/轉入)
     * @date 2021-04-13
     * @param {object} data {walletCode: 'player_balance',name: '場館名稱' }
     */
    async setWalletCode(data) {
      const { walletCode, name, type, status } = data
      if (status === '0') return
      this.inputDisplay[type] = name
      this.selectDialog.status = false
      // if (walletCode !== this.form[`${type}_walletcode`]) {
      // 重複點擊相同錢包不請求處理(還需完善,目前無法區隔第一次)
      //   if (type === 'from') await this.getSelectOrAllTransferWalletList(walletCode, type)
      // }
      this.form[`${type}_walletcode`] = walletCode

      // 設置顯示的金額
      this.dispalySelectWalletBalance(walletCode, type)

      // 檢查轉出/轉入是否符合原則
      // this.checkSelectedItem(walletCode, type)
      if (type === 'from') await this.getSelectOrAllTransferWalletList(walletCode, type)

      this.selectDialog.type = ''
      this.transferWalletList = []
    },

    /**
     * 設置目前選擇的 (轉出/轉入) 錢包餘額
     * @date 2021-04-13
     * @param {string} walletCode 場館錢包代號
     * @param {string} type 轉出 || 轉入
     */
    dispalySelectWalletBalance(walletCode, type) {
      if (walletCode === 'player_balance') {
        this.walletBalance[type] = this.userMainBalance
        return false
      }

      const wallet = this.walletListAll.filter(
        wallet => wallet.wallet_code === this.form[`${type}_walletcode`],
      )[0]
      this.walletBalance[type] = wallet?.balance
    },

    walletTransferSuccess() {
      // 更新中心/鎖定 錢包
      this.fetchWallet()

      // 更新場館錢包
      this.getUserWalletList()

      // AMOUNT RESET
      this.$refs.form.reset()

      this.show_alert({
        icon: 'done',
      })
      if (!this.walletModeTrueFalse && this.promotionEventWallet) this.getEventWalletDetails()
      // 轉換錢包 && active style 重置
      this.transferWalletList = []
      this.activeWallet = {}

      // 全部回收模式重置
      this.cancelRecycleWalletBtn()
      this.resetDialog()
    },

    walletTransferFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 單一錢包轉帳
     * @date 2021-04-14
     * @param {object} form 錢包換轉表單
     */
    async walletTransfer(form) {
      this.loading.transferBtn = true
      const res = await this.user_transfer_wallet(form)
      this.loading.transferBtn = false
      resStatus(res, this.walletTransferSuccess, this.walletTransferFail)
    },

    /**
     * 轉出 / 轉入 互換功能
     * @date 2021-04-14
     */
    changWallet() {
      const from = {
        walletCode: this.form.to_walletcode,
        name: this.inputDisplay.to,
        type: 'from',
      }
      const to = {
        walletCode: this.form.from_walletcode,
        name: this.inputDisplay.from,
        type: 'to',
      }
      this.setWalletCode(from)
      this.setWalletCode(to)
    },

    openRetrieveAllWalletBalanceDialog() {
      this.dialog.type = 'allRetrieve'
      this.dialog.status = true
    },

    /**
     * 全部回收
     * - 操作 loading
     * @date 2021-04-14
     */
    async retrieveAllWalletBalance() {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.retrieveAllWalletBalance.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.retrieveAllWalletBalance.setStatus(false)

      this.loading.allRetrieve = true
      const res = await this.user_retrieve_all_wallet_balance()
      this.loading.allRetrieve = false
      resStatus(res, this.walletTransferSuccess, this.walletTransferFail)
    },

    /**
     * 批次回收
     * - 將要轉換的 form，陣列化，傳送給 actions (axios.all)
     * @date 2021-04-14
     * @returns {function} 成功 / 失敗 執行方法
     */
    async mutiRetrieve() {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.mutiRetrieve.getStatus()) return false

      // 數秒後回複狀態
      this.eventLaunch.mutiRetrieve.setStatus(false)

      const transferWalletList = this.transferWalletList
      let apiRequestAry = []

      transferWalletList.forEach(item => {
        const form = {
          from_walletcode: item[0],
          to_walletcode: 'player_balance',
          amount: item[1],
        }
        apiRequestAry = [...apiRequestAry, { ...form }]
      })

      const res = await this.multiple_wallet_transfer_to_main_wallet(
        apiRequestAry,
      )
      const failInclude = res.find(item => item.status === '0')
      this.resetDialog()
      if (!failInclude) return this.walletTransferSuccess()
      return this.walletTransferFail()
    },

    resetDialog() {
      this.eventWalleRulesDialog.status = false
      this.eventWalleRulesContent = []
      this.dialog.status = false
      this.dialog.type = ''
      this.transferWalletList = []
    },

    openMutiRetrieveDialog() {
      this.dialog.type = 'retrieve'
      this.dialog.status = true
    },

    scrollToWalletArea() {
      window.scroll({
        top: 180,
        left: 0,
        behavior: 'smooth',
      })
    },

    /**
     * 新增(移除)轉換錢包 (一般錢包)
     * @date 2021-09-07
     * @param {any} walletCode 錢包代碼
     * @param {any} walletBalance 錢包金額
     */
    ToggleAddToTransferWallertList(walletCode, walletBalance, index) {
      // 清除 (全部回收) 錢包
      this.cancelRecycleWalletBtn()

      const isInside = this.transferWalletList.find((item) => item[0] === walletCode)

      if (!isInside) {
        // 如果項目不在轉換錢包內 => 新增
        this.activeWallet[walletCode] = true
        this.transferWalletList = [...this.transferWalletList, [walletCode, walletBalance]]
      } else {
        // 如果項目在轉換錢包內 => 刪除
        const insideIndex = this.transferWalletList.indexOf(isInside)
        this.transferWalletList.splice(insideIndex, 1)
        delete this.activeWallet[walletCode]
      }
      // Scroll
      if (index < 6 && this.transferWalletList.length > 0 && this.hasBalanceCanRetrieve && this.walletMode === 'single') this.scrollToWalletArea()
    },

    /**
     * 取消選取 (一般錢包)
     * @date 2021-09-07
     */
    clearSeleted() {
      this.activeWallet = {}
      this.transferWalletList = []
    },

    /**
     * 全部回收 (錢包)
     * @date 2021-09-07
     */
    recycleWalletBtn() {
      this.clearSeleted()
      this.transferWallertAll = !this.transferWallertAll

      if (this.transferWallertAll && this.walletMode === 'single') this.scrollToWalletArea()
    },

    /**
     * 取消選取 (全部回收錢包)
     * @date 2021-09-07
     */
    cancelRecycleWalletBtn() {
      this.transferWallertAll = false
    },

    moneySpec(money) {
      return spec(money)
    },
  },
}
