var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-56" },
    [
      _c("ToolbarTemplate1", [
        _c(
          "div",
          { attrs: { slot: "btn" }, slot: "btn" },
          [
            _c(
              "v-btn",
              { attrs: { icon: "" }, on: { click: _vm.getAllWallet } },
              [
                _c("Icon", {
                  staticClass: "title--text",
                  attrs: {
                    data: require("@icon/fetch.svg"),
                    width: "24px",
                    height: "24px",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [_c("Wallet")], 1),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "card-1" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "withdrawalToBank", attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "withdrawalToBank__title title--text font-weight-medium",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("myCenter.withdrawalMethods")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-container",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              !_vm.withdrawalIsFlatten
                                ? [
                                    _vm._l(
                                      _vm.userBankCardList,
                                      function (card) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: card._id,
                                            staticClass: "py-1 px-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "p-relative",
                                                class: [
                                                  _vm.form.bank_no ===
                                                  card.bank_no
                                                    ? "primary--text"
                                                    : "subtitle--text card1",
                                                ],
                                                attrs: {
                                                  block: "",
                                                  text:
                                                    _vm.form.bank_no ===
                                                    card.bank_no
                                                      ? false
                                                      : true,
                                                  outlined:
                                                    _vm.form.bank_no ===
                                                    card.bank_no
                                                      ? true
                                                      : false,
                                                  color:
                                                    _vm.form.bank_no ===
                                                    card.bank_no
                                                      ? "primary"
                                                      : "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setUserBankCardNumber(
                                                      card
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-center d-flex",
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        src:
                                                          _vm.baseImgUrl +
                                                          card.image,
                                                        height: "18",
                                                        width: "18",
                                                        contain: "",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getCardTitle(
                                                                card
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center bank_no",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          card.slug ||
                                                            card.bank_no.substr(
                                                              card.bank_no
                                                                .length - 4,
                                                              4
                                                            )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-1 px-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "card1",
                                            attrs: {
                                              to: "/user/card",
                                              block: "",
                                              text: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center d-flex",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center subtitle--text",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color: "secondary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-plus-circle "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "flashMessage.addCards"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "cur-btn",
                                            style:
                                              _vm.form.bank_id !== "usdt"
                                                ? _vm.activeTypeItem
                                                : "",
                                            attrs: {
                                              size: "small",
                                              color: "card2",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setBankId()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption mt-1 font-weight-medium rounded-xl subtitle--text",
                                                class: [
                                                  _vm.form.bank_id !== "usdt"
                                                    ? "primary--text"
                                                    : "subtitle--text",
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "myCenter.normalCurrency"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.cryptoList.length > 0
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "cur-btn",
                                                style:
                                                  _vm.form.bank_id === "usdt"
                                                    ? _vm.activeTypeItem
                                                    : "",
                                                attrs: { color: "card2" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setBankId("usdt")
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption mt-1 font-weight-medium rounded-xl subtitle--text",
                                                    class: [
                                                      _vm.form.bank_id ===
                                                      "usdt"
                                                        ? "primary--text"
                                                        : "subtitle--text",
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "myCenter.usdtCurrency"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.form.bank_id !== "usdt"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-4 pb-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title--text mb-1 subtitle-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "myCenter.bankAccountName"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-2",
                                              attrs: {
                                                "min-height": "36",
                                                dense: "",
                                                outlined: "",
                                                required: "",
                                                rules: [
                                                  _vm.rules.required.defrayName,
                                                ],
                                              },
                                              model: {
                                                value: _vm.form.defray_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "defray_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.defray_name",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.bank_id !== "usdt" &&
                                    _vm.currencySymbol !== "INR"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title--text mb-1 subtitle-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "myCenter.bankName"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-text-field",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  "max-height": "36",
                                                  readonly: "",
                                                  dense: "",
                                                  outlined: "",
                                                  required: "",
                                                  rules: [
                                                    _vm.rules.required.bankId,
                                                  ],
                                                  placeholder:
                                                    _vm.$t("form.missBank"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.bankDialog.status = true
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.dispalyInput.bankName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dispalyInput,
                                                      "bankName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dispalyInput.bankName",
                                                },
                                              },
                                              [
                                                _vm.activeBank
                                                  ? _c("div", {
                                                      staticClass:
                                                        "bank__inner d-flex align-center p-relative",
                                                      attrs: {
                                                        slot: "prepend-inner",
                                                      },
                                                      slot: "prepend-inner",
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "append" },
                                                    slot: "append",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "23" } },
                                                      [
                                                        _vm._v(
                                                          " mdi-chevron-right "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.bank_id !== "usdt" &&
                                    _vm.currencySymbol === "INR"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title--text mb-1 subtitle-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "myCenter.bankNameIFSC"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-2",
                                              attrs: {
                                                "min-height": "36",
                                                dense: "",
                                                outlined: "",
                                                required: "",
                                                placeholder: _vm.$t(
                                                  "form.missBankNameIFSC"
                                                ),
                                                rules: [
                                                  _vm.rules.required
                                                    .bankNameIFSC,
                                                  _vm.rules.ifsc.bankName,
                                                ],
                                              },
                                              model: {
                                                value: _vm.form.ifsc_code,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "ifsc_code",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.ifsc_code",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.bank_id === "usdt"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-4 pb-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title--text mb-1 subtitle-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("myCenter.alias")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "mt-2",
                                              attrs: {
                                                "min-height": "36",
                                                dense: "",
                                                outlined: "",
                                                required: "",
                                                placeholder:
                                                  _vm.$t("form.missSlug"),
                                                rules: [
                                                  _vm.rules.required.slug,
                                                ],
                                              },
                                              model: {
                                                value: _vm.form.slug,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "slug",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.slug",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.bank_id === "usdt"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0 mb-14",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title--text mb-1 subtitle-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("deposit.protocol")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("br"),
                                            _vm._l(
                                              _vm.protocolList,
                                              function (p, i) {
                                                return _c(
                                                  "v-btn",
                                                  {
                                                    key: i,
                                                    staticClass: "mt-2 cur-btn",
                                                    style:
                                                      p.code ===
                                                      _vm.form.protocol
                                                        ? _vm.activeTypeItem
                                                        : "",
                                                    attrs: {
                                                      size: "small",
                                                      depressed: "",
                                                      "active-class": "test",
                                                      color: "card2",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.form.protocol =
                                                          p.code
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "caption mt-1 font-weight-medium rounded-xl subtitle--text",
                                                        class: [
                                                          p.code ===
                                                          _vm.form.protocol
                                                            ? "primary--text"
                                                            : "subtitle--text",
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(p.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "title--text mb-1 subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.form.bank_id === "usdt"
                                                    ? _vm.$t(
                                                        "myCenter.cryptoAdress"
                                                      )
                                                    : _vm.$t(
                                                        "myCenter.bankCardNo"
                                                      )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-text-field",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              "max-height": "36",
                                              dense: "",
                                              placeholder:
                                                _vm.form.bank_id === "usdt"
                                                  ? _vm.$t(
                                                      "form.missWalletAdress"
                                                    )
                                                  : _vm.$t("form.missCardNo"),
                                              "single-line": "",
                                              outlined: "",
                                              inputmode: "numeric",
                                              required: "",
                                              "clear-icon": "mdi-close-circle",
                                              clearable: "",
                                              rules: [
                                                _vm.rules.required.bankNo,
                                                _vm.rules.bankNo.minLength,
                                                _vm.rules.bankNo.maxLength,
                                                _vm.rules.bankNo.usdtAdress,
                                              ],
                                            },
                                            on: { keyup: _vm.bankIdFormat },
                                            model: {
                                              value: _vm.form.bank_no,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "bank_no",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "form.bank_no",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "clear-icon" },
                                                slot: "clear-icon",
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-close-circle"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm.form.bank_id === "usdt"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "comment--text caption",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("myCenter.usdtAlert")
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.nation === "china" &&
                                    _vm.form.bank_id !== "usdt"
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title--text mb-1 subtitle-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "myCenter.provinceCity"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-text-field",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  "max-height": "36",
                                                  dense: "",
                                                  outlined: "",
                                                  required: "",
                                                  readonly: "",
                                                  placeholder:
                                                    _vm.$t("form.missProvince"),
                                                  value:
                                                    _vm.form.bank_province +
                                                    "  " +
                                                    _vm.form.bank_city,
                                                  rules: [
                                                    _vm.rules.required
                                                      .provinceNcity,
                                                  ],
                                                },
                                                on: { click: _vm.showSheet },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "append" },
                                                    slot: "append",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "23" } },
                                                      [
                                                        _vm._v(
                                                          " mdi-chevron-right "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-5 pb-0 px-0",
                                  attrs: { cols: "12" },
                                },
                                [_c("v-divider", { staticClass: "card-1" })],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "px-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "withdrawalToBank__title title--text font-weight-medium",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("global.withdraw") +
                                              _vm.$t("global.amount")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "px-0 pb-0",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "d-none",
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.amount.required,
                                                  ],
                                                },
                                                model: {
                                                  value: _vm.form.bank_no,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "bank_no",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.bank_no",
                                                },
                                              }),
                                              _c(
                                                "v-text-field",
                                                {
                                                  attrs: {
                                                    onkeyup:
                                                      "this.value=this.value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                    "max-height": "36",
                                                    dense: "",
                                                    placeholder:
                                                      _vm.$t("form.missAmount"),
                                                    "single-line": "",
                                                    outlined: "",
                                                    required: "",
                                                    "clear-icon":
                                                      "mdi-close-circle",
                                                    clearable: "",
                                                    type: "number",
                                                    inputmode: "decimal",
                                                    rules: [
                                                      _vm.rules.amount.required,
                                                      _vm.rules.amount.min,
                                                      _vm.rules.amount
                                                        .overBlance,
                                                      _vm.rules.amount
                                                        .overCount,
                                                      _vm.rules.amount.max,
                                                    ],
                                                  },
                                                  model: {
                                                    value: _vm.form.amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "amount",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression: "form.amount",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: {
                                                        slot: "clear-icon",
                                                      },
                                                      slot: "clear-icon",
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-close-circle"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "px-0 pb-0 caption comment--text",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pl-4 d-flex flex-column justify-start",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "myCenter.singleWithdrawRange"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.currencySymbol_ +
                                                            _vm.amount
                                                              .min_amount +
                                                            "~" +
                                                            _vm.currencySymbol_ +
                                                            _vm.amount
                                                              .max_amount
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "comment--text caption pt-0 px-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _vm.form.bank_id === "usdt"
                                                ? _c("div", [
                                                    _c(
                                                      "span",
                                                      { staticClass: "mr-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "form.CNY2USDT"
                                                            )
                                                          ) + "："
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "primary--text mr-5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.CNY2USDT
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      [
                                                        _c("Icon", {
                                                          staticClass: "mgb",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "3px",
                                                          },
                                                          attrs: {
                                                            data: require("@icon/info.svg"),
                                                            width: "12",
                                                            height: "12",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "deposit.rate"
                                                              )
                                                            )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "primary--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              `1 USDT ≈ ${_vm.usdtWithdrawalRate} ${_vm.currencySymbol}`
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "form.reachBetAmount"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.currencySymbol_ +
                                                              _vm.amount
                                                                .valid_bet_total
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "form.notReachBetAmount"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.currencySymbol_ +
                                                              _vm.unReachBetAmount
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "myCenter.withdrawAvailableTime"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.amount
                                                              .left_count
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "myCenter.withdrawAvailableAmount"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.currencySymbol_ +
                                                              _vm.amount
                                                                .left_amount
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "pa-0 pt-5" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    block: "",
                                                    color: "primary",
                                                    height: "44",
                                                    disabled: _vm.valid
                                                      ? false
                                                      : true,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setDialogState(
                                                        true,
                                                        "withdrawals"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "subtitle-1 btnText--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "global.withdraw"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            dialog:
              _vm.dialog.status &&
              _vm.dialog.type === "baseInfoHaveToCheck" &&
              !_vm.withdrawalIsFlatten,
            width: 280,
            height: 150,
          },
        },
        [
          _vm.dialog.type === "baseInfoHaveToCheck"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "text px-6",
                    attrs: { slot: "text" },
                    slot: "text",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("myCenter.firstDeposit")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "subtitle",
                    attrs: { slot: "btn", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.dialog.status = false
                      },
                    },
                    slot: "btn",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      slot: "btn",
                      to: "/user/personal",
                      text: "",
                      color: "primary",
                    },
                    slot: "btn",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "Dialog",
        {
          attrs: {
            dialog: _vm.dialog.status && _vm.dialog.type === "withdrawals",
            width: 280,
            height: 202,
          },
        },
        [
          _vm.dialog.type === "withdrawals"
            ? [
                _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                  _c("span", { staticClass: "subtitle-1 title--text" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("form.misswithdrawPassword")) + " "
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "px-6",
                    attrs: { slot: "text" },
                    slot: "text",
                  },
                  [
                    _c("span", { staticClass: "title--text caption" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("myCenter.withdrawPassword")) + " "
                      ),
                    ]),
                    _c(
                      "v-form",
                      {
                        ref: "pwdForm",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.pwdValid,
                          callback: function ($$v) {
                            _vm.pwdValid = $$v
                          },
                          expression: "pwdValid",
                        },
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "mt-2",
                          attrs: {
                            type: "password",
                            autofocus: "",
                            "max-height": "36",
                            dense: "",
                            placeholder: _vm.$t("form.missPassword"),
                            "single-line": "",
                            outlined: "",
                            required: "",
                            "clear-icon": "mdi-close-circle",
                            clearable: "",
                            rules: [_vm.rules.pwd.required],
                          },
                          model: {
                            value: _vm.form.withdrawal_password,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "withdrawal_password", $$v)
                            },
                            expression: "form.withdrawal_password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { attrs: { slot: "btn" }, slot: "btn" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "grey", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.setDialogState()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: !_vm.pwdValid ? true : false,
                          color: "primary",
                          text: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.userWithdrawals(_vm.form)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("global.confirmation")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.bankDialog.status,
            callback: function ($$v) {
              _vm.$set(_vm.bankDialog, "status", $$v)
            },
            expression: "bankDialog.status",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "bg" },
            [
              _c(
                "div",
                { staticClass: "p-fixed w-100 bg" },
                [
                  _c(
                    "v-toolbar",
                    { staticClass: "bg" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.closeSearchBankList },
                        },
                        [
                          _c("Icon", {
                            staticClass: "icon--text",
                            attrs: {
                              data: require("@icon/toolbar/arrow_left.svg"),
                              width: "16px",
                              height: "16px",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-toolbar-title", [
                        _vm._v(" " + _vm._s(_vm.$t("form.missBank")) + " "),
                      ]),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    {
                      staticClass: "bg",
                      attrs: { "three-line": "", subheader: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "search-area" },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "mx-4 mt-2 inputBg",
                              attrs: {
                                id: "searchInput",
                                autofocus: "",
                                "hide-details": "",
                                placeholder: _vm.$t("game.searchGame"),
                                rounded: "",
                                autocomplete: "off",
                                dense: "",
                              },
                              model: {
                                value: _vm.bankDialog.searchKeyword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.bankDialog,
                                    "searchKeyword",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "bankDialog.searchKeyword",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mt-0",
                                  attrs: { slot: "prepend-inner" },
                                  slot: "prepend-inner",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { slot: "btn", icon: "" },
                                      slot: "btn",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "white--text",
                                        attrs: {
                                          data: require("@icon/search.svg"),
                                          width: "24",
                                          height: "24",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-list",
                { staticClass: "bg pt-110px", attrs: { flat: "" } },
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { color: "primary" } },
                    _vm._l(_vm.filterBankList, function (bankItem) {
                      return _c(
                        "v-list-item",
                        {
                          key: bankItem._id,
                          on: {
                            click: function ($event) {
                              return _vm.setBankId(bankItem.code)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(bankItem.name),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-bottom-sheet",
        {
          model: {
            value: _vm.sheet.status,
            callback: function ($$v) {
              _vm.$set(_vm.sheet, "status", $$v)
            },
            expression: "sheet.status",
          },
        },
        [
          _c(
            "v-sheet",
            { staticClass: "text-center" },
            [
              _c(
                "v-list",
                { attrs: { height: "318px" } },
                [
                  !_vm.form.bank_province
                    ? [
                        _c(
                          "v-subheader",
                          { staticClass: "px-6" },
                          [
                            _c("span", { staticClass: "text-h6 title--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.selectProvince")) +
                                  " "
                              ),
                            ]),
                            _c("v-spacer"),
                            _c(
                              "v-icon",
                              {
                                attrs: { color: "title" },
                                on: { click: _vm.closeSheet },
                              },
                              [_vm._v(" mdi-close ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "overflow-auto h-318 pb-56 list" },
                          _vm._l(_vm.sheet.cities, function (item, province) {
                            return _c(
                              "v-list-item",
                              {
                                key: province,
                                staticClass: "text-left px-6",
                                on: {
                                  click: function ($event) {
                                    return _vm.setProvince(province)
                                  },
                                },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(province)),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    : [
                        _c(
                          "v-subheader",
                          { staticClass: "px-6" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-6",
                                attrs: { color: "primary" },
                                on: { click: _vm.resetProvince },
                              },
                              [_vm._v(" mdi-chevron-left ")]
                            ),
                            _c("span", { staticClass: "text-h6 title--text" }, [
                              _vm._v(
                                " " + _vm._s(_vm.form.bank_province) + " "
                              ),
                            ]),
                            _c("v-spacer"),
                            _c(
                              "v-icon",
                              {
                                attrs: { color: "title" },
                                on: { click: _vm.closeSheet },
                              },
                              [_vm._v(" mdi-close ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "overflow-auto h-318 pb-56 list" },
                          _vm._l(
                            _vm.sheet.cities[_vm.form.bank_province],
                            function (city, province) {
                              return _c(
                                "v-list-item",
                                {
                                  key: province,
                                  staticClass: "text-left px-6",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCities(city)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(city)),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }