import Alert from '@/components/dialog'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { resStatus } from '../../../utils/resUtils'

export default {
  components: {
    Alert,
  },

  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapState({
      main: state => state.wallet.main,
      locked: state => state.wallet.locked,
      free: state => state.wallet.free,
      player: state => state.wallet.player,
      locked_wallet: state => state.wallet.locked_wallet,
    }),

    ...mapGetters(['currencySymbol', 'currencySymbol_', 'theme', 'isLiffMode']),
  },

  mounted() {
    if (this.$route.name === 'transfer') return
    this.getUserWallet()
  },

  methods: {
    ...mapActions([
      'get_user_balance',
      'get_user_wallet_list',
      'show_alert',
    ]),

    ...mapMutations([
      'SET_FREE_BALANCE',
      'SET_PLAYER_BALANCE',
      'SET_LOCKED_WALLET_BALANCE',
    ]),

    getUserWalletListSuccess(data) {
      const { player, free } = data

      this.SET_FREE_BALANCE(free.find(wallet => wallet.wallet_code === 'free_wallet'))
      this.SET_PLAYER_BALANCE(player.find(wallet => wallet.wallet_code === 'player_balance'))
      this.SET_LOCKED_WALLET_BALANCE(player.find(wallet => wallet.wallet_code === 'locked_balance'))
    },

    walletTransferFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    async getUserWallet() {
      const res = await this.get_user_wallet_list()
      resStatus(res, this.getUserWalletListSuccess, this.walletTransferFail)
    },
  },
}
