<template>
  <div
    class="h-100dvh over-flow-y-scroll"
  >
    <component :is="layoutTemplate" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { curryTemplate } from '@/utils/template'

import template1 from './template/transferTempate1.vue'
import template3 from './template/transferTempate3.vue'
import template4 from './template/transferTempate4.vue'
import template7 from './template/transferTempate7.vue'

export default {
  computed: {
    ...mapGetters(['layout']),

    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
        template7,
      }
      return curryTemplate(this.layout, templateObj)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
