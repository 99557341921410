<template>
  <div class="countdown">
    <p v-if="timeLeft > 0">
      <span class="card2">{{ days }}</span>
      <span class="textfield--text">{{ $t('global.days') }}</span>
      <span class="card2">{{ hours }}</span>
      <span class="textfield--text">{{ $t('global.hours') }}</span>
      <span class="card2">{{ minutes }}</span>
      <span class="textfield--text">{{ $t('global.minutes') }}</span>
      <span class="card2">{{ seconds }}</span>
      <span class="textfield--text">{{ $t('global.second') }}</span>
    </p>
    <span v-if="timeLeft < 0 && endTime != -1">
      {{ $t('global.eventWalle') + $t('global.Expired') }}</span
    >
  </div>
</template>
<script>
import dayjs from 'dayjs'

export default {
  props: {
    endTime: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      timeLeft: 0, // 剩余时间（以秒为单位）
      timer: null,
    }
  },
  computed: {
    days() {
      return Math.floor(this.timeLeft / (24 * 60 * 60))
    },
    hours() {
      return Math.floor((this.timeLeft % (24 * 60 * 60)) / (60 * 60))
    },
    minutes() {
      return Math.floor((this.timeLeft % (60 * 60)) / 60)
    },
    seconds() {
      return this.timeLeft % 60
    },
  },
  mounted() {
    this.updateTimeLeft()
    if (this.timeLeft > 0) {
      this.startCountdown()
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    updateTimeLeft() {
      const nowUnix = dayjs().unix()
      this.timeLeft = this.endTime - nowUnix
    },
    startCountdown() {
      this.timer = setInterval(() => {
        this.timeLeft--
        if (this.timeLeft <= 0) {
          clearInterval(this.timer)
          this.$emit('time-up', false) // 使用 emit 將 false 傳回父層
        }
      }, 1000)
    },
  },
}
</script>
<style lang="scss">
.countdown {
  span {
    padding: 0 3px;
    min-width: 18px;
    text-align: center;
    display: inline-block;
    font-size: 0.7rem;
  }
  p {
    margin: 0;
  }
  .card2 {
    border-radius: 4px;
    padding: 3px 0;
  }
}
</style>
