<template>
  <div>
    <v-container>
      <v-row>
        <!-- 中心錢包 -->
        <v-col
          cols="6"
          class="px-1 pt-0"
        >
          <v-card
            color="card1"
            height="80"
          >
            <v-card-text class="px-0">
              <div class="text-center main">
                <div class="main__wallet">
                  <span class="primary--text subtitle-1">
                    {{ currencySymbol_ }}
                  </span>
                  <span class="title--text text-h5">{{ player.balance }}</span>
                </div>
                <div
                  class="main__description primary--text font-weight-bold caption"
                >
                  <span>
                    {{ player.name }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- 鎖定錢包 -->
        <v-col
          v-if="!isLiffMode"
          cols="6"
          class="px-1 pt-0"
        >
          <v-card
            color="card1"
            height="80"
          >
            <v-card-text class="px-0">
              <div class="text-center main">
                <div class="main__wallet">
                  <span class="secondary--text subtitle-1">
                    {{ currencySymbol_ }}
                  </span>
                  <span class="title--text text-h5">{{ locked_wallet.balance }}</span>
                </div>
                <div
                  class="main__description secondary--text font-weight-bold d-flex align-center justify-center caption"
                  @click="dialog = true"
                >
                  {{ locked_wallet.name }}
                  <Icon
                    data="@icon/help.svg"
                    width="16"
                    height="16"
                    class="ml-1 cursor-pointer link--text"
                  />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- 免費錢包 (LIFF)-->
        <v-col
          v-else
          cols="6"
          class="px-1 pt-0"
        >
          <v-card
            color="card1"
            height="80"
          >
            <v-card-text class="px-0">
              <div class="text-center main">
                <div class="main__wallet">
                  <span class="secondary--text subtitle-1">
                    {{ currencySymbol_ }}
                  </span>
                  <span class="title--text text-h5">{{ free.balance }}</span>
                </div>
                <div
                  class="main__description secondary--text font-weight-bold d-flex align-center justify-center caption"
                >
                  {{ free.name }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- 鎖定錢包 彈窗 -->
    <Alert
      :dialog="dialog"
      :width="280"
    >
      <div
        slot="title"
        class="title--text subtitle-1"
      >
        {{ $t('myCenter.lockWallet') }}
      </div>
      <div
        slot="text"
        class="px-6 text"
      >
        {{ $t('myCenter.lockWalletInfo') }}
      </div>
      <v-btn
        slot="btn"
        text
        color="primary"
        @click="dialog = false"
      >
        {{ $t('global.gotIt') }}
      </v-btn>
    </Alert>
  </div>
</template>

<script>
import walletMixin from './mixins/walletMixin'

export default {
  mixins: [walletMixin],
}
</script>

<style lang="scss" scoped>

</style>
