var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-56" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("Wallet"), _c("v-divider", { staticClass: "mt-3" })],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0 px-4", attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "withdrawalToBank__title title--text font-weight-medium",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("myCenter.paymentMethod")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-container",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "v-row",
                        {
                          class: [
                            {
                              "payment__list-hidden":
                                _vm.paymenetListAreaHidden,
                            },
                          ],
                        },
                        [
                          _vm._l(_vm.userPaymentList, function (payment) {
                            return _c(
                              "v-col",
                              {
                                key: payment._id,
                                staticClass:
                                  "px-0 py-2 d-flex flex-column justify-center align-center",
                                attrs: { cols: "3" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPayType(
                                      payment.code,
                                      payment.currency_symbol,
                                      payment.currency,
                                      payment.payment_platform
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "rounded caption",
                                  class: [
                                    _vm.activePayType === payment.code
                                      ? ""
                                      : "not-active-type",
                                  ],
                                  attrs: {
                                    src: payment.image,
                                    alt: payment.name,
                                    height: "72",
                                    width: "72",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "caption mt-1 font-weight-medium",
                                    class: [
                                      _vm.activePayType === payment.code
                                        ? "primary--text"
                                        : "subtitle--text",
                                    ],
                                  },
                                  [_vm._v(" " + _vm._s(payment.name) + " ")]
                                ),
                              ]
                            )
                          }),
                          _vm.bankCard[0]
                            ? [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "px-0 py-2 d-flex flex-column justify-center align-center",
                                    attrs: { cols: "3" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setPayType(
                                          "BANK",
                                          _vm.bankCard[0].currency_symbol
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "rounded caption",
                                      class: [
                                        _vm.form.paytype === "BANK"
                                          ? ""
                                          : "not-active-type",
                                      ],
                                      attrs: {
                                        src:
                                          _vm.baseImgUrl +
                                          _vm.bankCard[0].image,
                                        alt: _vm.bankCard[0].name,
                                        height: "72",
                                        width: "72",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption mt-1 font-weight-medium",
                                        class: [
                                          _vm.form.paytype === "BANK"
                                            ? "primary--text"
                                            : "subtitle--text",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.bankCard[0].name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm.usdtList.length
                            ? _vm._l(_vm.usdtList, function (usdtItem, index) {
                                return _c(
                                  "v-col",
                                  {
                                    key: index,
                                    staticClass:
                                      "px-0 py-2 d-flex flex-column justify-center align-center",
                                    attrs: { cols: "3" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setPayType(
                                          "USDT",
                                          usdtItem.currency_symbol,
                                          usdtItem.currency,
                                          usdtItem.payment_platform,
                                          { ...usdtItem, selIndex: index }
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "rounded caption",
                                      class: [
                                        _vm.activePayType === "USDT" &&
                                        _vm.activeUsdtIndex === index
                                          ? ""
                                          : "not-active-type",
                                      ],
                                      attrs: {
                                        src: _vm.baseImgUrl + usdtItem.image,
                                        alt: usdtItem.name,
                                        height: "72",
                                        width: "72",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption mt-1 font-weight-medium",
                                        class: [
                                          _vm.activePayType === "USDT" &&
                                          _vm.activeUsdtIndex === index
                                            ? "primary--text"
                                            : "subtitle--text",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(usdtItem.name) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.paymentListLength
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 text-center pt-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("div", { staticClass: "arrow" }, [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-center" },
                                      [
                                        _c("v-img", {
                                          class: [
                                            {
                                              "transform-rotate":
                                                !_vm.paymenetListAreaHidden,
                                            },
                                          ],
                                          attrs: {
                                            "max-height": "40",
                                            "max-width": "50",
                                            src: require("@/assets/icons/animationArrow.gif"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.paymenetListAreaHidden =
                                                !_vm.paymenetListAreaHidden
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-divider", { staticClass: "mt-3" }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.pipelineList.length > 0,
                              expression: "pipelineList.length > 0",
                            },
                          ],
                          staticClass: "card1 rounded",
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 subtitle-2",
                              attrs: { cols: "12" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t(_vm.pipeline.lv1.title[0])) +
                                  " "
                              ),
                            ]
                          ),
                          _vm._l(_vm.pipelineList, function (channel, index) {
                            return _c(
                              "v-col",
                              {
                                key: channel._id,
                                staticClass:
                                  "px-0 py-2 d-flex flex-column justify-center align-center",
                                attrs: { cols: "3" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPipelineItem(channel, index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "rounded caption",
                                    class: [
                                      _vm.activePipelineItem ===
                                      channel.code + channel.paytype_no + index
                                        ? ""
                                        : "not-active-type",
                                    ],
                                  },
                                  [_vm._v(" " + _vm._s(channel.name) + " ")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "caption mt-1 font-weight-medium",
                                    class: [
                                      _vm.activePipelineItem ===
                                      channel.code + channel.paytype_no + index
                                        ? "primary--text"
                                        : "subtitle--text",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("deposit.channel") +
                                            " " +
                                            (index + 1)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          false
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 subtitle-2",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(_vm.pipeline.lv2.title[0])
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          false
                            ? _vm._l(_vm.userPaymentList, function (payment) {
                                return _c(
                                  "v-col",
                                  {
                                    key: payment._id,
                                    staticClass:
                                      "py-2 d-flex flex-column justify-center align-center",
                                    attrs: { cols: "4" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setPayType(
                                          payment.code,
                                          payment.currency_symbol,
                                          payment
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 text-center rounded card1 py-1",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "caption mt-1 font-weight-medium",
                                            class: [
                                              _vm.form.paytype === payment.code
                                                ? "primary--text"
                                                : "subtitle--text",
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(payment.name) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.usdtListShow
                        ? _c(
                            "v-row",
                            { staticClass: "card1 rounded" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 subtitle-2",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("deposit.protocol")) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._l(
                                _vm.usdtList[_vm.activeUsdtIndex].protocols,
                                function (protocol, index) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: index,
                                      staticClass:
                                        "px-1 py-1 d-flex justify-start align-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          style:
                                            _vm.protocolClickStatus &&
                                            _vm.activeProtocolIndex === index
                                              ? _vm.activePaymentItem
                                              : "",
                                          attrs: {
                                            depressed: "",
                                            "active-class": "test",
                                            block: "",
                                            color: "card2",
                                          },
                                          on: {
                                            click: function ($event) {
                                              ;(_vm.protocolClickStatus = true),
                                                _vm.setProtocolItem(
                                                  protocol,
                                                  index
                                                )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "caption mt-1 font-weight-medium",
                                              class: [
                                                _vm.protocolClickStatus &&
                                                _vm.activeProtocolIndex ===
                                                  index
                                                  ? "primary--text"
                                                  : "subtitle--text",
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(protocol) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showAmountArea,
                      expression: "showAmountArea",
                    },
                  ],
                  staticClass: "pt-0",
                  attrs: { cols: "12" },
                },
                [
                  !_vm.isCompletedBaseinfoBeforeDeposit
                    ? _c(
                        "div",
                        [
                          _c("v-divider", { staticClass: "my-3" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "withdrawalToBank__title title--text font-weight-medium px-1",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("global.depositAmount")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.activePaymentType !== "option",
                                          expression:
                                            "activePaymentType !== 'option'",
                                        },
                                      ],
                                      staticClass: "px-0 pb-0 px-1",
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "form",
                                          model: {
                                            value: _vm.valid,
                                            callback: function ($$v) {
                                              _vm.valid = $$v
                                            },
                                            expression: "valid",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "max-height": "36",
                                              dense: "",
                                              placeholder:
                                                _vm.$t("form.missAmount"),
                                              "single-line": "",
                                              outlined: "",
                                              required: "",
                                              inputmode: "numeric",
                                              "clear-icon": "mdi-close-circle",
                                              onkeyup:
                                                "this.value=this.value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                              clearable: "",
                                              type: "number",
                                              rules: [
                                                _vm.rules.amount.required,
                                                _vm.rules.amount.min,
                                                _vm.rules.amount.max,
                                              ],
                                            },
                                            model: {
                                              value: _vm.form.amount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "amount",
                                                  _vm._n(
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                )
                                              },
                                              expression: "form.amount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.activePaymentType === "range"
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "px-0 pb-0 caption comment--text d-flex",
                                          attrs: { cols: "7" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column justify-start pr-5",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "myCenter.singleDepositRange"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.activeCurrencySymbol +
                                                        _vm.amount.min_amount +
                                                        "~" +
                                                        _vm.activeCurrencySymbol +
                                                        _vm.amount.max_amount
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm.activeCurrency === "USDT" ||
                                          (_vm.activePipeline &&
                                            _vm.activePipeline.currency ===
                                              "USDT")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column justify-start",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("myCenter.rate")
                                                      )
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.usdtRate)
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.activePayType === "USDT"
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 caption px-1",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("deposit.pay_expect")
                                              ) +
                                              " : "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [_vm._v(_vm._s(_vm.CNY2USDT))]
                                          ),
                                          _vm._v(" USDT "),
                                          _c("Icon", {
                                            staticClass: "ml-1 mgb",
                                            attrs: {
                                              data: require("@icon/info.svg"),
                                              width: "12",
                                              height: "12",
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("deposit.rate")) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  `1 USDT ≈ ${
                                                    _vm.activeCurrencySymbol
                                                  } ${
                                                    _vm.usdtList[
                                                      _vm.activeUsdtIndex
                                                    ].exchange_rate
                                                  }`
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      false
                                        ? _c(
                                            "v-container",
                                            [
                                              _c(
                                                "v-row",
                                                _vm._l(
                                                  _vm.fastAmountBtn,
                                                  function (item) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: item,
                                                        staticClass:
                                                          "py-1 px-1",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              "active-class":
                                                                "test",
                                                              block: "",
                                                              color: "card1",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.plusAmount(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "title--text caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      `+${item}`
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.activePaymentType === "option"
                                        ? _c(
                                            "v-container",
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-1" },
                                                _vm._l(
                                                  _vm.fastAmountBtnForOption,
                                                  function (item) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: item,
                                                        staticClass:
                                                          "py-1 px-1",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            style:
                                                              _vm.form
                                                                .amount === item
                                                                ? _vm.activePaymentItem
                                                                : "",
                                                            attrs: {
                                                              depressed: "",
                                                              "active-class":
                                                                "test",
                                                              block: "",
                                                              color: "card1",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.typeOptionSetAmount(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption",
                                                                class:
                                                                  _vm.form
                                                                    .amount ===
                                                                  item
                                                                    ? "primary--text"
                                                                    : "title--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showAmountArea,
                      expression: "showAmountArea",
                    },
                  ],
                  staticClass: "pt-0 px-4 mt-3",
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        height: "44",
                        block: "",
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deposit(_vm.form)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "subtitle-1 btnText--text" }, [
                        _vm._v(" " + _vm._s(_vm.$t("global.deposit")) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.dialog.status } },
        [
          _vm.dialog.type === "baseInfo"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "text px-6",
                    attrs: { slot: "text" },
                    slot: "text",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("myCenter.firstDeposit")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "subtitle",
                    attrs: { slot: "btn", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.dialog.status = false
                      },
                    },
                    slot: "btn",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      slot: "btn",
                      to: "/user/personal",
                      text: "",
                      color: "primary",
                    },
                    slot: "btn",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
                ),
              ]
            : _vm._e(),
          _vm.dialog.type === "url"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "w-100 text-center",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("div", { staticClass: "text-h6 title--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("global.deposit") + _vm.$t("global.info")
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "caption secondary--text mt-4 text-left" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("flashMessage.applySuccess", {
                                action: _vm.$t("global.deposit"),
                              })
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", [
                      _c("a", { attrs: { href: _vm.dialog.content } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("deposit.goToDeposit")) + " "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { attrs: { slot: "text" }, slot: "text" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text pa-0 text-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: { click: _vm.closeDialog },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("global.confirmation")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.dialog.type === "BANK"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "w-100 text-center",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("div", { staticClass: "text-h6 title--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("global.deposit") + _vm.$t("global.info")
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "amount primary--text mt-3" },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("global.dollar")) + " "),
                        ]),
                        _c("span", { attrs: { id: "amount" } }, [
                          _vm._v(" " + _vm._s(_vm.dialog.content.amount) + " "),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              "max-height": "30",
                              "max-width": "30",
                              icon: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText("amount")
                              },
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "title--text",
                              attrs: {
                                data: require("@icon/copy.svg"),
                                width: "16",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "caption secondary--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("global.remind")) + " "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { attrs: { slot: "text" }, slot: "text" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          { staticClass: "px-6" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("deposit.bank_name")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center",
                                attrs: { id: "bank_name", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.dialog.content.bank_name) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-end pa-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "max-height": "30",
                                      "max-width": "30",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText("bank_name")
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "title--text",
                                      attrs: {
                                        data: require("@icon/copy.svg"),
                                        width: "16",
                                        height: "16",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("deposit.bank_no")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center",
                                attrs: { id: "bank_no", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.dialog.content.bank_no) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-end pa-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "max-height": "30",
                                      "max-width": "30",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText("bank_no")
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "title--text",
                                      attrs: {
                                        data: require("@icon/copy.svg"),
                                        width: "16",
                                        height: "16",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("deposit.transfer_name")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center",
                                attrs: { id: "transfer_name", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.dialog.content.transfer_name) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-end pa-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "max-height": "30",
                                      "max-width": "30",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText("transfer_name")
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "title--text",
                                      attrs: {
                                        data: require("@icon/copy.svg"),
                                        width: "16",
                                        height: "16",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("deposit.transfer_address")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center caption",
                                attrs: { id: "transfer_address", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.addressLengthFormat) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-end pa-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "max-height": "30",
                                      "max-width": "30",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText("transfer_address")
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "title--text",
                                      attrs: {
                                        data: require("@icon/copy.svg"),
                                        width: "16",
                                        height: "16",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("deposit.paycode")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center",
                                attrs: { id: "pay_code", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.dialog.content.pay_code) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-end pa-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "max-height": "30",
                                      "max-width": "30",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText("pay_code")
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "title--text",
                                      attrs: {
                                        data: require("@icon/copy.svg"),
                                        width: "16",
                                        height: "16",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text pa-0 text-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: { click: _vm.closeDialog },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("global.confirmation")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.dialog.type === "USDT"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "w-100 text-center",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("div", { staticClass: "text-h6 title--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("global.deposit") + _vm.$t("global.info")
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "amount primary--text mt-3" },
                      [
                        _c("span", { attrs: { id: "amount" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.amountRoundDown(
                                  _vm.dialog.content.expected_amount_usdt
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("span", [_vm._v("USDT")]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              "max-height": "30",
                              "max-width": "30",
                              icon: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copyText("amount")
                              },
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "title--text",
                              attrs: {
                                data: require("@icon/copy.svg"),
                                width: "16",
                                height: "16",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "title--text font-14" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("deposit.deposit_expect") +
                              _vm.activeCurrencySymbol +
                              _vm.dialog.content.expected_amount
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { attrs: { slot: "text" }, slot: "text" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          { staticClass: "px-6" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("deposit.u_name")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center",
                                attrs: { id: "protocol", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.dialog.content.protocol) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-end pa-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "max-height": "30",
                                      "max-width": "30",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText("protocol")
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "title--text",
                                      attrs: {
                                        data: require("@icon/copy.svg"),
                                        width: "16",
                                        height: "16",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("deposit.deposit_adress")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center",
                                attrs: { id: "address", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.dialog.content.address) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-end pa-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "max-height": "30",
                                      "max-width": "30",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText("address")
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "title--text",
                                      attrs: {
                                        data: require("@icon/copy.svg"),
                                        width: "16",
                                        height: "16",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("deposit.paycode")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center",
                                attrs: { id: "pay_code", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.dialog.content.pay_code) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-end pa-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "max-height": "30",
                                      "max-width": "30",
                                      icon: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText("pay_code")
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "title--text",
                                      attrs: {
                                        data: require("@icon/copy.svg"),
                                        width: "16",
                                        height: "16",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text px-0 d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("deposit.rate")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "title--text px-0 d-flex align-center caption",
                                attrs: { id: "protocol", cols: "7" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `1 USDT ≈ ${_vm.activeCurrencySymbol} ${_vm.dialog.content.exchange_rate}`
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "caption subtitle--text pa-0 text-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: { click: _vm.closeDialog },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("global.confirmation")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }