<template>
  <div>
    <v-container>
      <v-row>
        <!-- 中心錢包 -->
        <v-col
          cols="12"
          class="px-1 pt-0"
        >
          <v-card
            height="48"
            class="d-flex align-center px-5"
            :class="`card--${theme}`"
          >
            <v-card-text class="pa-0">
              <div class="text-center main d-flex align-center justify-space-between">
                <div
                  class="main__description font-weight-bold"
                  :class="[theme === 'dark' ? 'white--text' : 'primary--text']"
                >
                  {{ $t('myCenter.mainWallet') }}
                </div>

                <div
                  class="main__wallet"
                  :class="[theme === 'dark' ? 'white--text' : 'primary--text']"
                >
                  <span
                    class="subtitle-1"
                  >
                    {{ currencySymbol_ }}
                  </span>
                  <span class="text-h5">{{ main }}</span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- 鎖定錢包 -->
        <v-col
          cols="12"
          class="px-1 pt-0"
        >
          <v-card
            height="48"
            class="d-flex align-center px-5"
            :class="`card--${theme}`"
          >
            <v-card-text class="pa-0">
              <div class="text-center main d-flex align-center justify-space-between">
                <div
                  class="main__description text--text font-weight-bold d-flex align-center justify-center"
                  @click="dialog = true"
                >
                  {{ $t('myCenter.lockWallet') }}
                  <Icon
                    data="@icon/help.svg"
                    width="16"
                    height="16"
                    class="ml-1 cursor-pointer primary--text"
                  />
                </div>
                <div class="main__wallet text--text">
                  <span class="subtitle-1">
                    {{ currencySymbol_ }}
                  </span>
                  <span class="text-h5">{{ locked }}</span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- 鎖定錢包 彈窗 -->
    <Alert
      :dialog="dialog"
      :width="280"
    >
      <div
        slot="title"
        class="title--text subtitle-1"
      >
        {{ $t('myCenter.lockWallet') }}
      </div>
      <div
        slot="text"
        class="px-6 text"
      >
        {{ $t('myCenter.lockWalletInfo') }}
      </div>
      <v-btn
        slot="btn"
        text
        color="primary"
        @click="dialog = false"
      >
        {{ $t('global.gotIt') }}
      </v-btn>
    </Alert>
  </div>
</template>

<script>
import walletMixin from '../mixins/walletMixin'

export default {
  mixins: [walletMixin],
}
</script>

<style lang="scss" scoped>
.card--dark {
  background: rgba(255, 255, 255, 0.12);
}
.card--light {
  background: #fff;
}
</style>
