<template>
  <div id="transfer" class="px-1">
    <ToolbarTemplate3>
      <div slot="btn">
        <v-btn text @click="fetchWallet">
          <Icon
            data="@icon/fetch.svg"
            width="24px"
            height="24px"
            class="white--text mr-1"
          />
          <span class="white--text">{{ $t('global.wallet') }}</span>
        </v-btn>
      </div>
    </ToolbarTemplate3>
    <v-container class="pt-56">
      <v-row>
        <v-col cols="12" class="bg p-sticky top-56">
          <WalletTemplate3 />
        </v-col>
        <!-- 免轉模式 -->
        <v-col cols="12" class="pt-0">
          <v-divider class="divider" />
          <v-container v-if="!promotionEventWallet">
            <v-row>
              <v-col cols="6" class="d-flex align-center px-0">
                <div class="d-flex align-center">
                  <!-- 切換轉換模式 -->
                  <label
                    for="mode"
                    class="subtitle-2 title--text font-weight-medium"
                  >
                    {{ $t('myCenter.automaticMode') }}
                  </label>
                  <v-switch
                    id="mode"
                    v-model="walletModeTrueFalse"
                    color="primary"
                    hide-details
                    class="pa-0 ma-0 ml-4"
                  />

                  <!-- loading allRetrieve -->
                  <v-progress-circular
                    v-show="loading.allRetrieve"
                    size="24"
                    width="2"
                    indeterminate
                    color="primary"
                  />
                </div>
              </v-col>

              <v-col cols="6" class="d-flex align-center justify-end px-0">
                <!-- 全部回收 -->
                <v-btn
                  v-if="false"
                  :disabled="!hasBalanceCanRetrieve"
                  text
                  color="primary"
                  height="36"
                  width="79"
                  @click="openRetrieveAllWalletBalanceDialog"
                >
                  <span class="font-weight-medium">
                    {{ $t('myCenter.recycleAll') }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="divider" />
        </v-col>

        <!-- wallet loading 錢包已取得 -->
        <template v-if="walletLoading">
          <!-- 轉換金額區塊 -->
          <v-col v-show="!walletModeTrueFalse" cols="12" class="pb-4 pt-0">
            <v-container>
              <v-row id="transfer-area" class="mb-1">
                <v-col
                  cols="5"
                  class="pt-2 px-0 pb-0"
                  @click="openSelectListDialog('from')"
                >
                  <!-- 轉出 -->
                  <v-text-field
                    v-model="inputDisplay.from"
                    class="pb-2"
                    filled
                    outlined
                    readonly
                    dense
                    max-height="36"
                    append-icon="mdi-chevron-right"
                    hide-details
                  />
                  <!-- 轉出金額 (顯示) -->
                  <span class="wallet-balance caption primary--text">
                    {{ currencySymbol_ + walletBalance.from }}
                  </span>
                </v-col>

                <v-col
                  cols="2"
                  class="pt-2 px-0 d-flex align-start justify-center"
                >
                  <v-btn class="chang__wallet" icon @click="changWallet">
                    <Icon
                      data="@icon/arrow__right.svg"
                      width="16"
                      height="8"
                      class="primary--text"
                    />
                  </v-btn>
                </v-col>

                <v-col
                  cols="5"
                  class="pt-2 px-0 pb-0"
                  @click="openSelectListDialog('to')"
                >
                  <!-- 轉入 -->
                  <v-text-field
                    v-model="inputDisplay.to"
                    class="pb-2"
                    hide-details
                    filled
                    readonly
                    outlined
                    max-height="36"
                    dense
                    append-icon="mdi-chevron-right"
                  />
                  <span class="wallet-balance caption primary--text">
                    {{
                      currencySymbol_ +
                      (walletBalance.to === 0 ? '0.00' : walletBalance.to)
                    }}
                  </span>
                </v-col>
              </v-row>

              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col
                    id="transfer__amount"
                    cols="12"
                    class="px-0 pr-1 d-flex pb-1"
                  >
                    <!-- 輸入金額 -->
                    <v-text-field
                      v-model.trim="form.amount"
                      outlined
                      height="40"
                      dense
                      single-line
                      :rules="[
                        rules.required,
                        rules.decimal,
                        rules.overZero,
                        rules.overMaxBalance,
                      ]"
                      :placeholder="$t('form.missAmount')"
                      inputmode="decimal"
                    >
                      <div slot="append">
                        <!-- 梭哈 -->
                        <v-btn
                          depressed
                          color="secondary"
                          class="showHand__btn"
                          min-height="40"
                          @click="form.amount = walletBalance.from"
                        >
                          <span class="primary--text caption">
                            {{ $t('global.showHand') }}
                          </span>
                        </v-btn>
                      </div>
                    </v-text-field>
                  </v-col>

                  <!-- 額度轉換 -->
                  <v-col cols="12" class="px-0 pt-0">
                    <v-btn
                      :loading="loading.transferBtn"
                      :disabled="!valid"
                      block
                      height="40"
                      color="transfer-btn"
                      class="rounded-0 white--text"
                      @click="walletTransfer(form)"
                    >
                      <span class="subtitle-1">
                        {{ $t('global.transfer') }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-divider class="divider" />
          </v-col>

          <v-col cols="12" class="pt-0 pl-0 pr-0">
            <v-tabs v-model="tab" background-color="bg" color="primary" grow>
              <v-tab v-for="(item, index) in tabText" :key="index">
                {{ item }}
              </v-tab>
            </v-tabs>
            <!-- 場館錢包 -->
            <v-container v-if="tab === 0" class="mt-3">
              <v-row>
                <!-- 全部(任一錢包有錢) -->
                <v-col
                  v-show="hasBalanceCanRetrieve"
                  cols="12"
                  class="px-1 py-1"
                >
                  <!-- 錢包名稱(全部) -->
                  <v-card
                    class="d-flex align-center justify-center flex-column rounded"
                    height="74"
                    :color="transferWallertAll ? '' : 'card1'"
                    :style="activeWalletBtnStyle(transferWallertAll)"
                    :disabled="walletBalanceTotal < 0.01"
                    @click="recycleWalletBtn"
                  >
                    <div class="line-height-12">
                      <span
                        class="caption font-weight-bold"
                        :class="
                          transferWallertAll
                            ? theme === 'light'
                              ? 'black--text'
                              : 'white--text'
                            : 'subtitle--text'
                        "
                      >
                        {{ $t('global.all') }}
                      </span>
                    </div>

                    <!-- 金額 -->
                    <div class="line-height-16">
                      <span
                        class="subtitle-2"
                        :class="
                          transferWallertAll
                            ? 'primary--text'
                            : theme === 'dark'
                            ? 'white--text'
                            : 'black--text'
                        "
                      >
                        {{
                          currencySymbol_ +
                          moneySpec(Number(walletBalanceTotal))
                        }}
                      </span>
                    </div>
                  </v-card>
                </v-col>

                <v-col
                  v-for="(game, index) in gameList"
                  :key="game.wallet_code"
                  cols="6"
                  class="px-1 py-1"
                  :class="game.status === '0' ? 'maintenanceBox' : ''"
                >
                  <!-- 維修中 父層要加 :class="item.status==='0'?'maintenanceBox':''"-->
                  <maintenance
                    v-if="game.status == '0'"
                    :bg-color="'#00000082'"
                  />
                  <!-- 所有錢包 -->
                  <v-card
                    class="p-relative d-flex flex-column align-center justify-center px-0"
                    height="80"
                    :color="activeWallet[game.wallet_code] ? '' : 'card1'"
                    :class="game.balance > 0 ? 'cursor-pointer' : ''"
                    :disabled="game.balance < 0.01"
                    :style="
                      activeWalletBtnStyle(activeWallet[game.wallet_code])
                    "
                    @click="
                      ToggleAddToTransferWallertList(
                        game.wallet_code,
                        game.balance,
                        index,
                      )
                    "
                  >
                    <!-- **錢包內容** -->
                    <!--活動tag -->
                    <div
                      v-if="game.source_wallet.type == 'event'"
                      class="eventAwardsTag d-flex"
                    >
                      <span class="secondary">已參加</span>
                      <span class="subtitle--text">{{
                        game.source_wallet.name
                      }}</span>
                    </div>
                    <!-- 錢包名稱 -->
                    <div class="line-height-12">
                      <span
                        class="caption font-weight-bold"
                        :class="
                          activeWallet[game.wallet_code]
                            ? theme === 'dark'
                              ? 'white--text'
                              : 'black--text'
                            : 'subtitle--text'
                        "
                      >
                        {{ game.wallet.name }}
                      </span>
                    </div>

                    <!-- 金額 -->
                    <div class="line-height-16">
                      <span
                        class="subtitle-2 font-weight-bold"
                        :class="[
                          !activeWallet[game.wallet_code] && game.balance > 0
                            ? theme === 'light'
                              ? 'black--text'
                              : 'white--text'
                            : 'subtitle--text',
                          {
                            'primary--text': activeWallet[game.wallet_code],
                          },
                        ]"
                      >
                        {{ currencySymbol_ + moneySpec(Number(game.balance)) }}
                      </span>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                v-if="transferWalletList.length > 0"
                class="p-sticky bottom-0 bg z-5"
              >
                <v-col cols="5" class="px-1">
                  <!-- 取消選取 -->
                  <v-btn
                    depressed
                    block
                    color="card1"
                    height="44"
                    class="rounded-0"
                    @click="clearSeleted"
                  >
                    <span class="title--text subtitle-2 font-weight-medium">
                      {{ $t('global.deselect') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col cols="7" class="px-1">
                  <!-- 批次回收 -->
                  <v-btn
                    depressed
                    block
                    color="transfer-btn"
                    height="44"
                    class="rounded-0 white--text"
                    @click="openMutiRetrieveDialog"
                  >
                    <span class="subtitle-2 font-weight-medium">
                      {{ $t('myCenter.batchRecycle') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- 全部回收 -->
              <v-row
                v-show="transferWallertAll"
                class="p-sticky bottom-0 bg z-5"
              >
                <v-col cols="6" class="px-1">
                  <!-- 取消選取 -->
                  <v-btn
                    depressed
                    block
                    color="card1"
                    height="44"
                    @click="cancelRecycleWalletBtn"
                  >
                    <span class="title--text subtitle-1 font-weight-medium">
                      {{ $t('global.deselect') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="px-1">
                  <!-- (全部)回收 -->
                  <v-btn
                    depressed
                    block
                    color="transfer-btn"
                    height="44"
                    class="white--text rounded-0"
                    @click="openRetrieveAllWalletBalanceDialog"
                  >
                    <span class="subtitle-2 font-weight-medium">
                      {{ $t('myCenter.recycle') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <!-- 活動錢包 -->
            <v-container v-else class="mt-3">
              <v-row>
                <v-col
                  v-for="(game, index) in eventWalletDetails"
                  :key="index"
                  cols="12"
                  class="px-1 py-1"
                >
                  <!-- 所有活動錢包 -->
                  <v-card
                    class="p-relative"
                    :color="
                      activeWallet[game.wallet_code] ? 'secondary' : 'card1'
                    "
                    :class="game.balance > 0 ? 'cursor-pointer' : ''"
                  >
                    <!-- 活動錢包內容 -->
                    <v-container>
                      <v-row>
                        <!-- 活動錢包名稱 -->
                        <v-col
                          cols="12"
                          class="activityWalletTitle pb-0 text-h6"
                        >
                          <strong>{{ game.wallet.name }}</strong>
                        </v-col>

                        <!-- 活動錢包 金額 / 規則說明 -->
                        <v-col cols="9" class="primary--text pb-0">
                          <strong>
                            {{ currencySymbol_ + game.wallet.balance }}</strong
                          >
                        </v-col>
                        <v-col
                          cols="3"
                          class="primary--text caption pl-0 pb-0"
                          @click="eventWalletRules(game.promotion.id)"
                        >
                          <v-icon size="16"> mdi-alert-circle-outline </v-icon>
                          {{ $t('myCenter.eventWalleRules') }}
                        </v-col>

                        <!-- 活動錢包 百分比軸 -->
                        <v-col cols="9" class="pb-0 d-flex align-center">
                          <v-progress-linear :value="getProgressValue(game)" />
                        </v-col>
                        <v-col cols="3" class="pl-0 pb-0">
                          {{ getProgressValue(game) }}%
                        </v-col>

                        <!-- 活動錢包 當前流水 -->
                        <v-col cols="12" class="caption">
                          {{ $t('myCenter.nowTurnover') }}
                          <span class="primary--text">{{
                            currencySymbol_ + game.valid_bet
                          }}</span>
                          <span class="subtitle--text"
                            >/{{ game.promotion.threshold }}</span
                          >
                        </v-col>

                        <!-- 活動錢包 倒數計時 -->
                        <v-col
                          v-if="isCountdownFinished[index]"
                          class="flipCountdown pt-0 d-flex align-end"
                          cols="6"
                        >
                          <countdown
                            :end-time="game.promotion.end_time_sec"
                            @time-up="handleTimeUp(false, index)"
                          />
                        </v-col>

                        <!-- 活動錢包 解鎖按鈕 -->
                        <v-col
                          v-if="isCountdownFinished[index]"
                          cols="6"
                          class="pl-0 pt-0"
                        >
                          <v-btn
                            :disabled="getProgressValue(game) < 100"
                            block
                            color="primary"
                            @click="
                              handlerUnlockEventAwards(
                                game.promotion.end_time_sec,
                                game.wallet.code,
                              )
                            "
                          >
                            <v-icon left>
                              {{
                                getProgressValue(game) >= 100
                                  ? 'mdi-lock-open-outline'
                                  : 'mdi-lock-outline'
                              }}
                            </v-icon>
                            <span class="btnText--text">
                              {{
                                getProgressValue(game) >= 100
                                  ? $t('myCenter.unlock')
                                  : $t('myCenter.lock')
                              }}
                            </span>
                          </v-btn>
                        </v-col>
                        <v-col v-else cols="6">
                          <span style="font-size: 0.75rem">{{
                            $t('global.eventWalle') + $t('global.Expired')
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </template>

        <!-- wallet loading 錢包取得中 -->
        <template v-else class="text-center">
          <v-col class="text-center mt-10">
            <v-progress-circular
              :size="70"
              :width="4"
              color="primary"
              indeterminate
            />
          </v-col>
        </template>
      </v-row>
    </v-container>

    <!-- 選擇 轉換錢包 彈窗 -->
    <SelectList :status="selectDialog.status">
      <v-row
        slot="title"
        class="p-sticky bg top-0 px-3 text-h6 title--text font-weight-medium"
      >
        <v-col class="">
          {{ $t('myCenter.selectVendorWallet') }}
        </v-col>
      </v-row>

      <!-- LIST -->
      <v-row slot="list">
        <!-- 中心錢包 -->
        <!-- <v-col
          cols="12"
          :class="[
            {
              secondary:
                form[`${selectDialog.type}_walletcode`] === 'player_balance',
            },
            'rounded'
          ]"
          @click="
            setWalletCode({
              walletCode: 'player_balance',
              name: $t('myCenter.mainWallet'),
              type: selectDialog.type,
            })
          "
        >
          <div class="px-3">
            {{ $t('myCenter.mainWallet') }}
          </div>
        </v-col> -->
        <!-- LOOP API 錢包 -->
        <v-col
          v-for="game in selectDialog.list"
          :key="game.code"
          cols="12"
          :class="[
            {
              secondary: form[`${selectDialog.type}_walletcode`] === game.code,
            },
            {
              notAllow: game.status === '0',
            },
            'rounded',
          ]"
          @click="
            setWalletCode({
              walletCode: game.code,
              name: game.name,
              type: selectDialog.type,
              status: game.status,
            })
          "
        >
          <div class="px-3">
            {{
              game.name +
              ` ${game.status === '0' ? `(${$t('game.maintain')})` : ''}`
            }}
          </div>
        </v-col>
      </v-row>
    </SelectList>

    <!-- 活動錢包 說明 彈窗 -->
    <Dialog :dialog="eventWalleRulesDialog.status">
      <v-row
        slot="title"
        class="p-sticky bg top-0 px-3 text-h6 title--text font-weight-medium w-100"
      >
        <v-col cols="12" class="">
          {{ $t('myCenter.eventWalleRules') }}
        </v-col>
        <v-img
          class="rounded"
          :src="baseImgUrl + eventWalleRulesContent.content_image"
          contain
        >
          <!-- lazy progress -->
          <template #placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5" />
            </v-row>
          </template>
        </v-img>
        <div
          v-show="eventWalleRulesContent.content"
          class="px-3 pt-2 news-content fix-img-size"
        >
          <div v-html="eventWalleRulesContent.content" />
        </div>
      </v-row>
      <v-btn slot="btn" text color="primary" @click="resetDialog">
        {{ $t('global.gotIt') }}
      </v-btn>
    </Dialog>

    <!-- dialog -->
    <Dialog :dialog="dialog.status" :width="280" :height="140">
      <!-- 全部回收 -->
      <template v-if="dialog.type === 'allRetrieve'">
        <div slot="text" class="px-6">
          {{ $t('flashMessage.allWalletTransferToMainWallet') }}
        </div>

        <v-btn slot="btn" text @click="resetDialog">
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="retrieveAllWalletBalance"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>

      <!-- 批次收回 -->
      <template v-if="dialog.type === 'retrieve'">
        <div slot="text" class="px-6 text-text">
          {{ $t('flashMessage.recycleAll') }}
        </div>

        <v-btn slot="btn" text @click="resetDialog">
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn slot="btn" text color="primary" @click="mutiRetrieve">
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>

      <!-- 是否開啟免轉 (尚有餘額) -->
      <template v-if="dialog.type === 'transfer'">
        <div slot="text" class="px-6">
          {{ $t('flashMessage.otherWalletsHaveBalances') }}
        </div>

        <v-btn slot="btn" text @click="setUserMode({ wallet_type: 'single' })">
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn slot="btn" text color="primary" @click="resetDialog">
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import transferMixin from '../mixins/transferMixin'

export default {
  mixins: [transferMixin],
}
</script>

<style lang="scss" scoped>
.lt-6 {
  top: 3px;
  left: 3px;
}

.line-height-12 {
  line-height: 12px;
}

.line-height-16 {
  line-height: 16px;
}
.w-100 {
  width: 100%;
}
.bottom-0 {
  bottom: 0;
}

.top-0 {
  top: 0;
}

.top-56 {
  top: 56px;
}
.z-5 {
  z-index: 5;
}
.wallet-balance {
  line-height: 12px;
}

.transfer-btn {
  background: linear-gradient(180deg, #e32a10 0%, #9f1e0b 100%);
}
.activityWalletTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.maintenance + div {
  filter: blur(0px);
  z-index: 1;
}
.eventAwardsTag {
  width: 100%;
  font-size: 0.7rem;
  margin-bottom: 8px;
  height: 16px;
  span {
    &:first-child {
      border-radius: 0 4px 4px 0;
      padding: 0 4px;
      margin-right: 4px;
      min-width: 45px;
    }
    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.notAllow {
  cursor: not-allowed;
}
</style>
