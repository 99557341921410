<template>
  <!-- 取款 -->
  <div class="pt-56">
    <v-container>
      <v-row>
        <v-col cols="12">
          <WalletTemplate3 />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="withdrawalToBank pt-0 px-4"
        >
          <div class="withdrawalToBank__title title--text font-weight-medium">
            {{ $t('myCenter.withdrawalMethods') }}
          </div>
          <v-container class="mt-4">
            <v-row>
              <!-- 使用者銀行卡列表 -->
              <v-col
                v-for="card in userBankCardList"
                :key="card._id"
                cols="12"
                class="py-1 px-0"
              >
                <v-btn
                  block
                  :text="form.bank_no === card.bank_no ? false : true"
                  :outlined="form.bank_no === card.bank_no ? true : false"
                  :color="form.bank_no === card.bank_no ? (theme === 'dark' ? 'secondary' : 'primary') : ''"
                  class="p-relative d-flex justify-space-between"
                  :class="[
                    form.bank_no === card.bank_no
                      ? 'primary--text'
                      : 'subtitle--text card1',
                  ]"
                  @click="setUserBankCardNumber(card)"
                >
                  <div class="text-center d-flex">
                    <v-img
                      :src="baseImgUrl + card.image"
                      class="mr-2"
                      height="18"
                      width="18"
                      contain
                    />
                    <div class="d-flex align-center">
                      {{ getCardTitle(card) }}
                    </div>
                  </div>
                  <div class="d-flex align-center bank_no">
                    {{ card.slug || card.bank_no.substr(card.bank_no.length - 4, 4) }}
                  </div>
                </v-btn>
              </v-col>

              <!-- 新增卡片 -->
              <v-col
                cols="12"
                class="py-1 px-0"
              >
                <v-btn
                  to="/user/card"
                  block
                  text
                  :class="`puls-card-btn--${theme}`"
                >
                  <div class="subtitle--text w-100">
                    + {{ $t('flashMessage.addCards') }}
                  </div>
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                class="px-0"
              >
                <div
                  class="withdrawalToBank__title title--text font-weight-medium"
                >
                  {{ $t('global.withdraw') + $t('global.amount') }}
                </div>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      class="px-0 pb-0"
                    >
                      <v-form
                        ref="form"
                        v-model="valid"
                      >
                        <v-text-field
                          v-model="form.bank_no"
                          class="d-none"
                          :rules="[rules.amount.required]"
                        />
                        <!-- 取款金額 -->
                        <v-text-field
                          v-model.trim="form.amount"
                          onkeyup="this.value=this.value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          height="56"
                          :placeholder="$t('form.missAmount')"
                          single-line
                          outlined
                          required
                          clear-icon="mdi-close-circle"
                          clearable
                          type="number"
                          inputmode="decimal"
                          :background-color="theme === 'light' ? 'white' : 'none'"
                          :rules="[
                            rules.amount.required,
                            rules.amount.min,
                            rules.amount.overBlance,
                            rules.amount.overCount,
                            rules.amount.max,
                          ]"
                        >
                          <div slot="clear-icon">
                            <v-icon>mdi-close-circle</v-icon>
                          </div>
                        </v-text-field>
                      </v-form>
                    </v-col>

                    <v-col
                      cols="12"
                      class="px-0 pt-1 pb-0 caption comment--text"
                    >
                      <div class="d-flex">
                        <span>
                          {{ $t('myCenter.singleWithdrawRange') }}
                        </span>
                        <span>
                          {{
                            currencySymbol_ +
                              amount.min_amount +
                              '~' +
                              currencySymbol_ +
                              amount.max_amount
                          }}
                        </span>
                      </div>
                    </v-col>

                    <!-- 今日可用額度 -->
                    <v-col
                      cols="12"
                      class="comment--text caption pt-0 px-0 mt-1"
                    >
                      <!-- 預計到帳＆參考匯率 -->
                      <div v-if="form.bank_id === 'usdt'">
                        <span class="mr-2">{{ $t('form.CNY2USDT') }}</span>
                        <span class="primary--text mr-5">
                          {{ CNY2USDT }}
                        </span>
                        <span><Icon
                          data="@icon/info.svg"
                          width="12"
                          height="12"
                          class="mgb"
                          style="margin-bottom: 3px"
                        /> {{ $t("deposit.rate") }}</span>
                        <span class="primary--text">
                          {{ `1 USDT ≈ ${usdtWithdrawalRate} ${currencySymbol}` }}
                        </span>
                      </div>
                      <!-- 已達投注額 -->
                      <div class="mt-1">
                        <span class="mr-2">{{ $t('form.reachBetAmount') }}</span>
                        <span class="primary--text">
                          {{ currencySymbol_ + amount.valid_bet_total }}
                        </span>
                      </div>

                      <!-- 未達投注額 -->
                      <div class="mt-1">
                        <span class="mr-2">{{ $t('form.notReachBetAmount') }}</span>
                        <span class="primary--text">
                          {{ currencySymbol_ + unReachBetAmount }}
                        </span>
                      </div>

                      <!-- 可以次數 -->
                      <div class="mt-1">
                        <span class="mr-2">{{ $t('myCenter.withdrawAvailableTime') }}</span>
                        <span class="primary--text">
                          {{ amount.left_count }}
                        </span>
                      </div>

                      <!-- 可用額度 -->
                      <div class="mt-1">
                        <span class="mr-2">{{ $t('myCenter.withdrawAvailableAmount') }}</span>
                        <span class="primary--text">
                          {{ currencySymbol_ + amount.left_amount }}
                        </span>
                      </div>
                    </v-col>
                    <v-col class="pa-0 pt-5">
                      <v-btn
                        block
                        color="primary"
                        class="rounded-0"
                        height="44"
                        :disabled="valid ? false : true"
                        @click="setDialogState(true, 'withdrawals')"
                      >
                        <span class="white--text subtitle-1">
                          {{ $t('global.withdraw') }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog (完善基本資料/取款) -->
    <DialogTemplate3
      :dialog="dialog.status"
      :width="320"
      :height="dialog.type === 'withdrawals' ? 216 : 200"
    >
      <!-- 如果要提款 -->
      <template v-if="dialog.type === 'withdrawals'">
        <div
          slot="title"
          class="text-center w-100 subtitle-1 py-2 white--text"
        >
          {{ $t('form.misswithdrawPassword') }}
        </div>

        <div
          slot="text"
          class="px-6"
        >
          <v-form
            ref="pwdForm"
            v-model="pwdValid"
            lazy-validation
          >
            <!-- 輸入 提款密碼 -->
            <v-text-field
              v-model="form.withdrawal_password"
              type="password"
              class="mt-6"
              height="56"
              :label="$t('myCenter.withdrawPassword')"
              :placeholder="$t('form.missPassword')"
              outlined
              required
              clear-icon="mdi-close-circle"
              clearable
              :rules="[rules.pwd.required]"
            />
          </v-form>
        </div>

        <div slot="btn">
          <v-btn
            color="grey"
            text
            @click="setDialogState()"
          >
            {{ $t('global.cancel') }}
          </v-btn>

          <!-- 使用者取款 submit -->
          <v-btn
            :disabled="!pwdValid ? true : false"
            color="primary"
            text
            @click="userWithdrawals(form)"
          >
            {{ $t('global.confirmation') }}
          </v-btn>
        </div>
      </template>

      <!-- 如果沒有完善基本資料 -->
      <template v-if="dialog.type === 'baseInfoHaveToCheck'">
        <div
          slot="title"
          class="text-center w-100 subtitle-1 py-2 white--text"
        >
          {{ $t('myCenter.completeInfo') }}
        </div>
        <div
          slot="text"
          class="text px-6 mt-6"
        >
          {{ $t('myCenter.firstDeposit') }}
        </div>
        <v-btn
          slot="btn"
          text
          class="subtitle"
          @click="dialog.status = false"
        >
          {{ $t('global.cancel') }}
        </v-btn>
        <v-btn
          slot="btn"
          to="/user/personal"
          text
          color="primary"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>
    </DialogTemplate3>
  </div>
</template>

<script>
import indexMixin from '../mixins/index'

export default {
  name: 'WithdrawalTemplate3',
  mixins: [indexMixin],
}
</script>

<style lang="scss" scoped>
.puls-card-btn--dark {
  border: 1px dashed  rgba(255, 255, 255, 0.5);
}
.puls-card-btn--light {
  background: white;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}

</style>
