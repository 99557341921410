import Dialog from '@/components/dialog'
import DialogTemplate3 from '@/components/dialog/DialogTemplate3'
import Wallet from '@/components/base/Wallet'
import WalletTemplate3 from '@/components/base/template/WalletTempate3'
import ToolbarTemplate1 from '@/components/base/template/ToolbarTemplate1'
import ToolbarTemplate3 from '@/components/base/template/ToolbarTemplate3'
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { clickEventInterval } from '@/utils/event'
import { isValidateErc20, isValidateTrc20, isValidateIFSC } from '@/utils/validate'
import { Decimal } from 'decimal.js'
import { amountRoundDown } from '@/utils/money'

export default {
  components: {
    Dialog,
    DialogTemplate3,
    Wallet,
    WalletTemplate3,
    ToolbarTemplate1,
    ToolbarTemplate3,
  },

  data: () => ({
    cryptoList: [],
    valid: true,
    pwdValid: true,
    bankCode: '',

    form: {
      defray_name: '', // 開戶姓名
      bank_city: '', // 開戶市
      bank_province: '', // 開戶省
      bank_id: '', // 銀行代碼
      bank_no: '', // 銀行卡號
      amount: '', // 提款金額
      slug: '', // 別名
      protocol: '', // 協議
      withdrawal_password: '', // 提款密碼
      ifsc_code: '', // 銀行分行(印度盧比)
    },
    userBankCardList: [],
    userBaseInfoStatus: false,

    amount: {
      left_amount: 0,
      left_count: 0,
      max_amount: 0,
      min_amount: 0,
      valid_bet_total: 0, // 有效投注額
      valid_bet_requirement: 0, // 投注額要求
    },

    dialog: {
      status: false,
      type: '',
    },

    // 執行過後，是否可以再打 api
    eventLaunch: {
      userWithdrawals: null,
    },

    dispalyInput: {
      bankName: '',
    },

    bankDialog: {
      status: false,
      bankList: [],
      searchKeyword: '',
    },

    sheet: {
      status: false,
      cities: [],
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'currencySymbol', 'currencySymbol_', 'theme', 'lang', 'nation', 'layout']),
    ...mapState({
      formatNation: state => state.websiteData.phone_format_nation,
      // 主錢包
      main: state => state.wallet.main,
      locked: state => state.wallet.locked,
      settings: state => state.websiteData.settings,

      // 取款方式
      withdrawalMethod: state => state.websiteData.withdrawal_method,
      userName: state => state.user.userData.member.username,
    }),
    /**
     * 取得主色色碼
     * @returns {string} 主色色碼
     */
    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },
    usdtWithdrawalRate() {
      return this.$store.state.websiteData?.usdt_exchange_rate_withdrawal || '-'
    },
    CNY2USDT() {
      if (!this.form.amount) return '-'
      if (this.usdtWithdrawalRate === '-') return '-'
      const divRes = new Decimal(this.form.amount).div(this.usdtWithdrawalRate)
      return `${this.amountRoundDown(divRes)} U`
    },
    /**
     * 點擊時，btn 主色外框
     * @returns {string} border style (主色外框)
     */
    activeTypeItem() {
      return {
        border: `1px solid ${this.primaryColor} !important`,
      }
    },
    protocolList() {
      return this.form.bank_id
        ? this.cryptoList.find(c => c.code === this.form.bank_id)?.protocols
        : []
    },

    // 未達投注額
    unReachBetAmount() {
      const result = (Number(this.amount.valid_bet_requirement) - Number(this.amount.valid_bet_total)).toFixed(2)
      return result <= 0 ? 0 : result
    },

    activeBank() {
      return this.withdrawalIsFlatten
        ? this.bankDialog.bankList.find(item => item.code === this.form.bank_id)
        : []
    },
    bankCitiesInput() {
      if (this.form.bank_province && this.form.bank_city) { return this.form.bank_province + '  ' + this.form.bank_city }
      return undefined
    },

    /**
     * 搜尋銀行 (篩選 關鍵字 搜尋的銀行)
     * @date 2021-10-20
     * @returns {array} 符合搜尋的銀行項目
     */
    filterBankList() {
      if (!this.bankDialog.searchKeyword) return this.bankDialog.bankList
      return this.bankDialog.bankList.filter(this.searchBankList)
    },

    rules() {
      return {
        required: {
          defrayName: value => !!value || this.$t('form.missDefrayName'),
          bankId: value => !!value || this.$t('form.missBank'),
          bankNo: value => !!value || ((this.form.bank_id === 'usdt' ? this.$t('form.missWalletAdress') : this.$t('form.missCardNo'))),
          provinceNcity: value => !!value || this.$t('form.missProvince'),
          slug: value => !!value || this.$t('form.missSlug'),
          bankNameIFSC: value => !!value || this.$t('form.missBankNameIFSC'),
        },
        ifsc: {
          bankName: bank_no => {
            // 11碼、英文、數字 (印度盧比)
            return isValidateIFSC(bank_no) || this.$t('form.BankNameIFSCFail')
          },
        },
        amount: {
          required: value => !!value || this.$t('form.missAmount'),
          // 超過中心錢包
          overBlance: val =>
            val <= Number(this.main) ||
            this.$t('form.errorAmountNoMoney'),

          // 超過今日可用額度
          overCount: val =>
            val <= this.amount.left_amount ||
            this.$t('form.errorAmountDailyLimit'),

          min: value =>
            value >= this.amount.min_amount ||
            this.$t('form.errorAmountOutofRange', {
              min: this.amount.min_amount,
              max: this.amount.max_amount,
            }),

          max: value =>
            value <= this.amount.max_amount ||
            this.$t('form.errorAmountOutofRange', {
              min: this.amount.min_amount,
              max: this.amount.max_amount,
            }),
        },
        bankCardNo: {
          required: value => !!value || this.$t('form.missCardNo'),
        },
        pwd: {
          required: val => !!val || this.$t('form.missPassword'),
        },
        bankNo: {
          usdtAdress: bank_no => {
            if (!bank_no) return false
            if (!this.form.protocol && this.form.bank_id === 'usdt') return this.$t('form.protocolFirst')

            const validationMap = {
              trc20: isValidateTrc20,
              erc20: isValidateErc20,
            }
            const validationFunction = validationMap[this.form.protocol]
            if (this.form.bank_id === 'usdt' && validationFunction && !validationFunction(bank_no)) {
              return this.$t('form.walletAdressFail')
            }
            return true
          },
          minLength: bank_no => {
            if (!bank_no) return false
            if (this.form.bank_id !== 'usdt') {
              if (this.currencySymbol === 'THB') {
              // 不能 大於 10個字元 + 2個2字元空格 (泰國)
                return bank_no.length >= 14 || this.$t('form.errorCardNo')
              }
              // 不能 少於 16個字元 + 3個2字元空格 (中國)
              return bank_no.length >= 22 || this.$t('form.errorCardNo')
            }
          },
          // 不能 大於 19字元 + 4個2字元空格
          maxLength: bank_no => {
            if (!bank_no) return false
            if (this.form.bank_id !== 'usdt') {
              if (this.currencySymbol === 'THB') {
                // 不能 少於 10個字元 + 2個2字元空格 (泰國)
                return bank_no.length <= 14 || this.$t('form.errorCardNo')
              }
              return bank_no.length <= 27 || this.$t('form.errorCardNo')
            }
          },
        },
      }
    },

    /*
      後台設定[提款]為直接攤平
      withdrawalIsFlatten: true
      1. 隱藏銀行卡
      2. 取款頁不強制跳轉，不跳出訊息「初次新增銀行卡，請先完善個人資料」
    */
    withdrawalIsFlatten() {
      return this.withdrawalMethod === 'direct'
    },
  },

  watch: {
    userBaseInfoStatus(status) {
      if (status) return this.setDialogState(true, 'baseInfoHaveToCheck')
    },
    'form.bank_id'(newStatus) {
      // console.log(newStatus)
      if (this.withdrawalIsFlatten && this.layout === 'template1') {
        this.form.bank_no = ''
        this.form.bank_city = ''
        this.form.bank_province = ''
      }
    },
    'form.protocol'(newStatus) {
      // console.log(newStatus)
      if (this.withdrawalIsFlatten) this.form.bank_no = ''
    },
  },

  mounted() {
    this.getAllWallet()
    this.set_toolbar_title(this.$t('global.withdraw'))
    this.getUserBankList()

    // if (this.withdrawalIsFlatten) {
    this.getBankList()
    // }
    this.form.defray_name = this.userName

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_user_bankcard_list',
      'get_bank_list',
      'get_nation_city',
      'user_withdrawals',
      'show_alert',
      'get_user_balance',
    ]),
    amountRoundDown(amt) {
      return amountRoundDown(amt)
    },
    /**
     * 成功取得 取得使用者銀行卡列表
     * @date 2021-03-31
     * @param {object} 銀行卡列表資料
     */
    getUserBankListSuccess(data) {
      const {
        bankcards,
        cryptocurrency_accounts,
        completed_baseinfo,
        left_amount,
        left_count,
        max_amount,
        min_amount,
        valid_bet_total,
        valid_bet_requirement,
      } = data

      this.userBankCardList = [...bankcards, ...cryptocurrency_accounts]
      this.userBaseInfoStatus = completed_baseinfo !== 'Y'
      this.amount.left_count = left_count
      this.amount.left_amount = left_amount
      this.amount.max_amount = max_amount
      this.amount.min_amount = min_amount
      this.amount.valid_bet_total = valid_bet_total || 0
      this.amount.valid_bet_requirement = valid_bet_requirement || 0
    },

    /**
     * 取得使用者銀行卡列表
     * @date 2021-03-31
     */
    async getUserBankList() {
      const userBankCardList = await this.get_user_bankcard_list()
      resStatus(userBankCardList, this.getUserBankListSuccess)
    },
    getCardTitle(card) {
      return card.ifsc_code || `${card.bank_name} ${card.protocol ? `-${card.protocol}` : ''}`
    },
    setUserBankCardNumber(card) {
      // console.log(card)
      this.form.bank_no = card.bank_no
      this.form.bank_id = card.bank_id
      this.form.defray_name = this.userName
      if (this.currencySymbol === 'INR' && this.form.bank_id !== 'usdt') this.form.ifsc_code = card.ifsc_code
      if (this.form.bank_id === 'usdt') {
        this.form.ifsc_code = ''
        this.form.protocol = card.protocol
      }
    },
    setDialogState(status = false, typeName = '') {
      if (!status) {
        this.$refs.pwdForm.reset()
      }
      this.dialog.status = status
      this.dialog.type = typeName
    },
    /**
     * 使用者取款 (成功)
     * @date 2021-08-04
     */
    async userWithdrawalsSuccess() {
      this.show_alert({
        icon: 'done',
        text: this.$t('global.withdraw') + this.$t('global.success'),
      })
      this.getAllWallet()
      this.getUserBankList()
      this.setDialogState()
      await this.$refs.form.reset()
      this.form.defray_name = this.userName
    },
    /**
     * 使用者取款 (失敗)
     * @date 2021-08-04
     */
    userWithdrawalsFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('global.withdraw') + this.$t('global.fail'),
      })
      this.setDialogState()
    },
    /**
     * 使用者取款
     * @date 2021-08-04
     * @param {object} form
     */
    async userWithdrawals(form) {
      const cloneForm = { ...form }
      // 狀態未初始化 不可打
      if (!this.eventLaunch.userWithdrawals.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.userWithdrawals.setStatus(false)
      if (this.currencySymbol !== 'CNY' || (this.currencySymbol === 'CNY' && this.layout !== 'template1') || cloneForm.bank_id === 'usdt') {
        delete cloneForm.bank_city
        delete cloneForm.bank_province
      }
      if (cloneForm.bank_id === 'usdt') {
        // delete cloneForm.defray_name
        cloneForm.ifsc_code = ''
        if (!this.form.defray_name) cloneForm.defray_name = this.userName
      } else {
        delete cloneForm.protocol
        cloneForm.bank_no = this.bankIdRepalce(cloneForm.bank_no)
      }
      if (!this.withdrawalIsFlatten) {
        delete cloneForm.slug
        delete cloneForm.bank_city
        delete cloneForm.bank_province
      }
      if (this.currencySymbol === 'INR' && cloneForm.bank_id !== 'usdt') cloneForm.bank_id = 'ifsc'

      // return
      const res = await this.user_withdrawals(cloneForm)
      resStatus(res, this.userWithdrawalsSuccess, this.userWithdrawalsFail)
    },
    async getAllWallet() {
      await this.getUserWallet('player_balance')
      await this.getUserWallet('locked_balance')
    },
    /**
     * 取得使用者錢包 (中心錢包 / 鎖定錢包)
     * @date 2021-03-31
     * @param {string} walletcode "locked_balance"：鎖定錢包 - "player_balance"：主錢包、中心錢包 - "gf_ia_esport"：小艾電競錢包
     */
    async getUserWallet(walletcode) {
      await this.get_user_balance({ walletcode })
    },
    /**
     * 開戶省份 (彈窗關閉)
     * @date 2021-07-06
     * @returns {any}
     */
    async closeSheet() {
      this.sheet.status = false
    },
    closeSearchBankList() {
      this.bankDialog.status = false
    },
    /**
     * 信用卡移除格式化
     * @date 2021-03-29
     * @param {string} bankId
     * @returns {string} 移除空格的信用卡號
     */
    bankIdRepalce(bankId) {
      return bankId.split('  ').join('')
    },

    /**
     * 信用卡號格式化
     * @date 2021-03-29
     */
    bankIdFormat() {
      if (this.form.bank_id === 'usdt') return
      this.form.bank_no = this.form.bank_no
        .replace(/\D/g, '')
        .replace(/\s/g, '')
        .replace(/(\d{4})(?=\d)/g, '$1  ')
    },
    /**
     * 開戶省份 (彈窗開啟)
     * @date 2021-07-06
     * @param {any} e
     * @param {string}} nation=this.nation 國家名稱
     */
    async showSheet(e, nation = this.nation) {
      this.sheet.status = true
      const citiesData = await this.getCityData(nation)
      this.sheet.cities = citiesData
    },
    /**
     * 設置語系方法
     * @date 2021-04-06
     * @param {string}} naion 國家名稱
     * @returns {object}} 國家省城資料
     */
    async getCityData(nation) {
      return await this.get_nation_city(nation)
    },

    setBanckList(bankList) {
      this.bankDialog.bankList = bankList.banks
      this.cryptoList = bankList.cryptocurrencies
    },

    async getBankList() {
      const bankList = await this.get_bank_list()
      resStatus(bankList, this.setBanckList)
    },
    setBankId(bankId) {
      // console.log('setBankId', bankId)
      if (!bankId) {
        // 按鈕選取到一般提幣
        this.form.bank_id = ''
        this.form.protocol = ''
        this.form.slug = ''
        this.dispalyInput.bankName = ''
        this.$forceUpdate()
        return
      }
      this.form.bank_id = bankId
      if (this.form.bank_id !== 'usdt') {
        this.dispalyInput.bankName = this.activeBank.name
      }
      this.bankDialog.status = false
      this.$forceUpdate()
    },
    setProvince(province) {
      this.form.bank_province = province
      this.listScrollToTop()
      this.$forceUpdate()
    },
    resetProvince() {
      this.form.bank_province = ''
      this.$forceUpdate()
      this.listScrollToTop()
    },

    setCities(city) {
      this.form.bank_city = city
      this.$forceUpdate()
      this.sheet.status = false
    },
    listScrollToTop() {
      document.querySelector('.list').scrollTo(0, 0)
    },
  },
}
