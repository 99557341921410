var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "countdown" }, [
    _vm.timeLeft > 0
      ? _c("p", [
          _c("span", { staticClass: "card2" }, [_vm._v(_vm._s(_vm.days))]),
          _c("span", { staticClass: "textfield--text" }, [
            _vm._v(_vm._s(_vm.$t("global.days"))),
          ]),
          _c("span", { staticClass: "card2" }, [_vm._v(_vm._s(_vm.hours))]),
          _c("span", { staticClass: "textfield--text" }, [
            _vm._v(_vm._s(_vm.$t("global.hours"))),
          ]),
          _c("span", { staticClass: "card2" }, [_vm._v(_vm._s(_vm.minutes))]),
          _c("span", { staticClass: "textfield--text" }, [
            _vm._v(_vm._s(_vm.$t("global.minutes"))),
          ]),
          _c("span", { staticClass: "card2" }, [_vm._v(_vm._s(_vm.seconds))]),
          _c("span", { staticClass: "textfield--text" }, [
            _vm._v(_vm._s(_vm.$t("global.second"))),
          ]),
        ])
      : _vm._e(),
    _vm.timeLeft < 0 && _vm.endTime != -1
      ? _c("span", [
          _vm._v(
            " " + _vm._s(_vm.$t("global.eventWalle") + _vm.$t("global.Expired"))
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }